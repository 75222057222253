import React, { useEffect, useRef } from 'react'
import './AfterPayment.css'
import Navbar from '../navbar/Navbar'
import lottie from 'lottie-web';
import animationData from '../../pages/payment_success/payment _successful.json';
import animatedFailData from '../../pages/payment_faliure/payment_failed.json';
import AfterconfirmanimatedData from '../../pages/afterconfirm/Send_Request.json'

function AfterPayment(props) {
  const animationContainer = useRef(null);
  
  useEffect(()=>{
      lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: props.page === 'fail' ? animatedFailData : props.page === 'request sent' ? AfterconfirmanimatedData : animationData,
      });
    },[]);

  return (
    <div className='after_payment_page'>
    <Navbar redirectNav = {true}/>
    <div className='success_payment_page'>
       <div className='payment_text'>
       <p>{props.text}</p>
       </div>
           <div className='payment_animation_container'>
           <div ref={animationContainer} className={`${props.page === 'request sent' ? 'afterconfirm' : 'payment_animation'}`}></div>
           </div>
    </div>
    </div>    
  )
}

export default AfterPayment
