import { Box, Typography } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Clover_Line() {
    const domain = process.env.REACT_APP_FRONT_FLOW_URL;
    const navigate = useNavigate(); 
    const [img , setImg] = useState('/assets/img/pos_banner.png');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleOnclick = () => {
        navigate(`/3rd-party-order-intigrations-with-pos`);
    }
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        if (windowWidth < 600) {
            setImg('/assets/img/pos_mobiles_banner.png');
        } 
        else if (windowWidth < 1200) {
            setImg('/assets/img/pos_small_banner.png');
        } 
        else
        {
            setImg('/assets/img/pos_banner.png');
        }
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [windowWidth]);

    return (
        <div className='clover_line' onClick={handleOnclick}>
            {/* <marquee behavior="scroll" direction="left" scrollamount="15"> */}
            {/* onMouseOver={() => document.querySelector('marquee').stop()} onMouseOut={() => document.querySelector('marquee').start()} */}
                {/* <button >
                    <Link to={`/3rd-party-order-intigrations-with-pos`}>FREE INTEGRATION TO CLOVER POS & SUPER MENU POS (ALL 3RD PARTY ORDERS TO POS)</Link>
                </button> */}
            {/* </marquee> */}
            <img src={img}/>
            <Box className='pos_line_box'>
            <Typography className='pos_line_text'>FREE INTEGRATION TO CLOVER POS & SUPER MENU POS </Typography>
             <Typography className='pos_line_text'>(ALL 3RD PARTY ORDERS TO POS)</Typography>        
            </Box>
            <div id='pricing_stop_scroll'></div>
        </div>
    )
}

export default Clover_Line