import React, { useEffect } from 'react'
import './brand_animation.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Brand_animation() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
       <div className='brand_animation_container'>
      <h1 data-aos="fade-up" data-aos-duration="500">Brands That Trust us</h1>
      {/* <marquee scrollamount="12" style={{border:'1px solid black'}}>  */}
        <div className='brands_logos'>
         <div>
         <img src='/assets/img/1.svg' loading="lazy" />
         </div>
         <div>
         <img src='/assets/img/3.svg' loading="lazy" />
         </div>
         <div>
         <img src='/assets/img/2.svg' loading="lazy" />
         </div>
         <div>
         <img src='/assets/img/5.svg' loading="lazy" />
         </div>
         <div>
         <img src='/assets/img/4.svg' loading="lazy" />
         </div>
         <div>
         <img src='/assets/img/delivery.svg' loading="lazy" />
         </div>
         <div>
         <img src='/assets/img/postmats.svg' loading="lazy" />
         </div>
        </div>
      {/* </marquee> */}
    </div>
      <div id='about_stop_scroll' style={{height:'5px'}}></div>
    </>
 
  )
}

export default Brand_animation
