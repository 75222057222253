
import React, { useEffect, useState } from 'react';
import './Section.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link as ScrollLink } from 'react-scroll';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const Section = () => {
  const slides = [
    {
      heading1: `Printhook's`,
      heading2: 'All Your Orders in One Place',
      discription:`Experience the convenience of having every order centralized in one place for a more organized and effective workflow.<span> sorting for smooth and effective operations.</span>`,
      imgUrl: '/assets/img/slide1_img.svg'
    },
    {
      heading1: `Effectively Sorting`,
      heading2: 'Your Orders With Ease',
      discription:'Optimize your business with simplified workflows and streamlined processes. Benefit from the ease of efficient order sorting for smooth and effective operations.',
      imgUrl: '/assets/img/slide2_img.svg'
    },
    {
      heading1: `Your Orders,`,
      heading2: 'Our Top Priority and Commitment',
      discription:'We prioritize your orders with unwavering commitment, placing your satisfaction at the forefront. Our dedicated pursuit of excellence ensures we fulfill your needs with top-tier priority.',
      imgUrl: '/assets/img/slide3_img.svg'
    }
  ]
  return (
    <div className='section_page'>
      <Swiper modules={[Autoplay , Pagination]} pagination={{
        clickable: true,
      }} 
        autoplay = {{delay:4000 , disableOnInteraction: false}}
        loop={true}
      className='my_slider'
      >
        {
          slides.map((slide) => (
            <SwiperSlide>
              <div className='slide'>
                <div className='section_info'>
                  <div className='section_heddings'>
                    <h2 className='heading_one'>
                      {slide.heading1}
                    </h2>
                    <h2 className='heading_two'>
                    {slide.heading2}
                    </h2>
                    <p dangerouslySetInnerHTML={{ __html: slide.discription }}/>
                  </div>
                  <div className='section_getstart'>
                    <div className='section_getstart_button'>
                      <ScrollLink to="pricing_stop_scroll">
                        <button>Get started 
                          <div className='forward_arrow'>
                            <ArrowForwardIcon/> 
                          </div>
                        </button>
                      </ScrollLink>
                    </div>
                  </div>
                </div>
                <div className='section_image'>
                  <img src={slide.imgUrl} loading="lazy"/>
                </div>
              </div>
            </SwiperSlide>
          ))
        }

      </Swiper>
    </div>

  );
};

export default Section;
