import React, { useEffect } from 'react'
import './virtual_kitchen.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Virtual_kitchen() {
    useEffect(() => {
        AOS.init();
      }, [])
  return (
    <>
      <div className='virtual_kitchen'>
        <h1 data-aos="fade-up"  data-aos-duration="500">Virtual Kitchen</h1>
        <p data-aos="fade-up"  data-aos-duration="500">Experience the convenience of a virtual kitchen with <span>Printhook's</span>. By subscribing to our services, you entrust us with the management
of your virtual kitchen's orders, streamlining the order management process for you.</p>
      </div>
      <div className='product_features_video'>
        <div className='text-overlay'>
          <h1>Got my focus on Cooking thanks to Printhook's for making my life easy</h1>
        </div>
        <video autoPlay={true} muted loop>
          <source src='/assets/img/printhooksvideo.mp4' type="video/mp4" />
        </video>
      </div>
    </>
  )
}

export default Virtual_kitchen
