import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';
import ResourceLayout from '../../layout/ResourceLayout';

function Customer_support_services() {
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };

  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Printhook's offers comprehensive customer support services to assist restaurants in optimizing their order management process. From technical assistance to troubleshooting, our dedicated support team is here to ensure your success."
        />
        <meta
          name='keywords'
          content='customer support services, technical assistance, troubleshooting, order management, Printhook'
          s
        />
        <title>Customer Support Services | Printhook's</title>
      </Helmet>

      <ResourceLayout show_navbar={false}>
        <div className='single_page'>
          <div
            className='single_page_front_image'
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/img/pos_integration_image.svg')`,
            }}
          ></div>
          <div className='single_page_heading'>
            <h1>Customer Support Services | Printhook's</h1>
          </div>

          <div className='order_managment'>
            <h3>
              Enhance Your Restaurant's Efficiency with Printhook's Customer
              Support Services
            </h3>

            <h3>Introduction</h3>
            <p>
              Printhook's understands the importance of providing exceptional
              customer support to ensure the success of your restaurant. That's
              why we offer comprehensive customer support services designed to
              assist you every step of the way in optimizing your order
              management process.
            </p>

            <h3>Our Customer Support Services</h3>
            <p>
              At Printhook's, we're committed to helping you achieve operational
              excellence. Our customer support services include:
            </p>
            <ol>
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Technical Assistance:
                </span>
                <br />
                Our experienced support team is available to provide technical
                assistance and guidance on using Printhook's order management
                platform. Whether you're setting up your account, configuring
                workflows, or troubleshooting issues, our experts are here to
                help.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Troubleshooting:</span>
                <br />
                Encountering a problem with your order management system? Our
                support team is here to diagnose and resolve issues promptly to
                minimize disruptions to your operations. We'll work diligently
                to identify the root cause of the problem and implement
                effective solutions to keep your restaurant running smoothly.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>User Training:</span>
                <br />
                New to Printhook's platform? Our customer support team offers
                comprehensive user training to familiarize you and your staff
                with all the features and functionalities of our order
                management system. We'll guide you through the setup process,
                demonstrate key features, and provide best practices to help you
                maximize the benefits of our platform.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>24/7 Assistance:</span>
                <br />
                Emergencies can happen at any time, which is why our support
                team is available 24/7 to assist you. Whether it's late at night
                or early in the morning, you can count on us to provide timely
                assistance and resolve any issues you may encounter.
              </li>
            </ol>

            <h3>Why Choose Printhook's Customer Support Services?</h3>
            <p>
              Partnering with Printhook's for customer support offers numerous
              benefits for your restaurant:
            </p>
            <ul>
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Dedicated Support Team:
                </span>
                Our knowledgeable and friendly support team is dedicated to
                ensuring your success and satisfaction.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Prompt Response:</span> We
                prioritize rapid response times to address your inquiries and
                resolve issues promptly.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Tailored Solutions:</span>
                Our support services are tailored to meet your specific needs
                and challenges, ensuring personalized assistance and guidance.
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Continuous Improvement:
                </span>
                We're committed to ongoing improvement and innovation,
                constantly striving to enhance our support services to better
                serve our customers.
              </li>
            </ul>

            <h3>Get Started Today</h3>
            <p>
              Ready to experience the benefits of Printhook's customer support
              services? Contact us today to learn more and schedule a
              consultation. Our team is here to help you optimize your order
              management process and achieve success in your restaurant
              operations.
            </p>

            <h3>Contact Information</h3>
            <p>
              For inquiries or assistance, please contact our customer support
              team:
              <br />
              Phone: 888-881-2808
              <br />
              Email:{' '}
              <a href='mailto:info@Printhooks.com'>info@Printhooks.com</a>
            </p>
            <p>
              We look forward to partnering with you on your journey to
              restaurant excellence.
            </p>
          </div>
        </div>
      </ResourceLayout>
    </>
  );
}

export default Customer_support_services;
