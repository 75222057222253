import React from 'react'
import About_menu_control from '../../components/about_menu_control/About_menu_control';
function About() {
  const pera1 = `<span>Printhook's </span>is a centralized order management platform catering to restaurant owners and franchise managers. Our platform serves as a hub for streamlined monitoring and tracking of food orders from various delivery partners.`;

  const pera2 = `<span>Printhook's </span> simplifies the complexities of order handling by aggregating order information, providing an efficient and organized approach. It offers a comprehensive solution to handle everything related to order management effectively and efficiently, all in one centralized location. From order status to delivery management, <span>Printhook's </span> provides a seamless experience for optimizing food  delivery operations.`
  return (
    <>
      <About_menu_control heading = 'Who we are ?' pera1 = {pera1} pera2 = {pera2} img = {'/assets/img/about.svg'}/>
    </>
    
  )
}
export default About
