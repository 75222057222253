import React, { useEffect, useState } from 'react';
import './Single_page.css';
import ResourceLayout from '../../layout/ResourceLayout';
function Single_page({ image, heading, img1, peras, children, navbar }) {
  return (
    <>
      <ResourceLayout show_navbar={navbar}>
        <div className='single_page'>
          <div
            className='single_page_front_image'
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${image}')`,
            }}
          ></div>
          <div className='single_page_heading'>
            <h1>{heading}</h1>
          </div>
          <div className='single_page_discription'>
            <div className='single_page_discription_pera'>
              {peras.map((pera) => (
                <p dangerouslySetInnerHTML={{ __html: pera }}></p>
              ))}
            </div>
            <div className='singlr_page_discription_image'>
              <img src={img1} loading='lazy' />
            </div>
          </div>
          {children}
        </div>
      </ResourceLayout>
    </>
  );
}

export default Single_page;
