import React from 'react'
import './thirdparties_order_integration.css'
import { Box, Container, Grid, Typography } from '@mui/material'
import ResourceLayout from '../../layout/ResourceLayout'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import InquiryFrom from './InquiryFrom'

const deliver_parteners = [
    { img: '/assets/img/Uber Eats.svg' },
    { img: '/assets/img/GRUBHUB.svg' },
    { img: '/assets/img/DOORDASH.svg' },
    { img: '/assets/img/GRABULL.svg' },
    { img: '/assets/img/EZCATER.svg' },
    { img: '/assets/img/Deliverycom.svg' },
    { img: '/assets/img/Postmates.svg' }
]

const pos_networks = [
    { img: '/assets/img/logo-clover-h.png' },
    { img: '/assets/img/supermenu-site-logo.png' },
    { img: '/assets/img/Square,_Inc._logo.svg.png' }
]
const services = [
    {
        heading: 'Automatically accept order',
        peragraph: ' All the orders will print automatically in your kitchen.',
        img: '/assets/img/service-1.png',
        backcolor: '#ffebef'
    },
    {
        heading: 'No hardware',
        peragraph: 'We will use your POS to print the orders.',
        img: '/assets/img/service-2.png',
        backcolor: '#e3ffed'
    },
    {
        heading: 'Consolidate reporting',
        peragraph: 'Your orders will be in sync with your POS.',
        img: '/assets/img/service-3.png',
        backcolor: '#fff5d3'
    },
    {
        heading: 'Fast onboarding',
        peragraph: 'Our Onboarding is simple and easy: no sales call required, you can literally do it yourself.',
        img: '/assets/img/service-4.png',
        backcolor: '#ffebef'
    }
]

function Thirdparties_order_integration() {
    const navigate = useNavigate()
    const handleContactclick = () => {
        navigate('/contact')
    }

    return (
        <ResourceLayout show_navbar={true}>
            <Box className='thirdparties_order_integration_page'>
                <Box className='solution_banner'>
                    <Container sx={{ textAlign: 'center' }} className='solution_banner_container'>
                        <Typography variant='h5' fontWeight={600} marginBottom={1}>Our Solution</Typography>
                        <Typography variant='h3' fontWeight={600} marginBottom={1}>3rd-Party Order Integration</Typography>
                        <Typography variant='h6' >Effortlessly manage online orders. Automatically accept incoming orders from delivery apps that will seamlessly integrate with your existing hardware and POS system.</Typography>
                    </Container>
                </Box>
                <Box className='partner-section'>
                    <Container >
                        <Typography variant='h4' fontWeight={700} sx={{ textAlign: 'center' }}>Connect all of your third-party
                        </Typography>
                        <Typography variant='h4' fontWeight={700} sx={{ textAlign: 'center' }}>delivery services directly into your POS
                        </Typography>
                    </Container>
                    <Box>
                        <Box className='point_of_sale_head' sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                            <Typography fontWeight={600}
                                sx={{
                                    backgroundColor: '#818cf8',
                                    color: '#FFFFFF',
                                    width: 'fit-content',
                                    borderRadius: 1,
                                    fontSize: '1.1rem',
                                    boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.19)',
                                    padding: '0.5rem 1.5rem',
                                    zIndex: '100'
                                }}
                            >Point of sales Network
                            </Typography>
                        </Box>
                        <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                            {pos_networks.map((pos) => (
                                <Box
                                    sx={
                                        {
                                            background: '#ffffff',
                                            borderRadius: ' 25px',
                                            padding: '20px',
                                            minWidth: ' 200px',
                                            minHeight: '125px',
                                            display: ' flex',
                                            justifyContent: ' center',
                                            alignItems: 'center',

                                        }
                                    }>
                                    <img src={pos.img} style={{ width: '150px' }} />
                                </Box>
                            ))}
                            <Box
                                sx={
                                    {
                                        background: '#ffffff',
                                        borderRadius: ' 25px',
                                        padding: '20px',
                                        minWidth: ' 200px',
                                        minHeight: '125px',
                                        display: ' flex',
                                        flexDirection: 'column',
                                        justifyContent: ' center',
                                        alignItems: 'center',

                                    }
                                }>
                                <Typography fontWeight={600}>Integrate your own pos</Typography>
                                <Typography sx={{ cursor: 'pointer', textDecoration: 'underline' }} fontWeight={600}>Learn more</Typography>
                            </Box>
                        </Container>
                    </Box>

                    <Box className='delivery_parter_head' sx={{ display: 'flex', justifyContent: 'center', marginTop: '2rem' }}>
                        <Typography fontWeight={600}
                            sx={{
                                backgroundColor: '#212832',
                                color: '#FFFFFF',
                                width: 'fit-content',
                                borderRadius: 1,
                                fontSize: '1.1rem',
                                boxShadow: '0px 10px 16px rgba(0, 0, 0, 0.19)',
                                zIndex: '100',
                                padding: '0.5rem 1.5rem'
                            }}
                        >
                            Third Parties
                        </Typography>
                    </Box>
                    <Container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '20px', flexWrap: 'wrap' }}>
                        {deliver_parteners.map((partner) => (
                            <Box
                                sx={
                                    {
                                        background: '#ffffff',
                                        borderRadius: ' 25px',
                                        padding: '20px',
                                        minWidth: ' 200px',
                                        minHeight: '125px',
                                        display: ' flex',
                                        justifyContent: ' center',
                                        alignItems: 'center',

                                    }
                                }>
                                <img src={partner.img} style={{ width: '150px' }} />
                            </Box>
                        ))}
                        <Box
                                sx={
                                    {
                                        background: '#ffffff',
                                        borderRadius: ' 25px',
                                        padding: '20px',
                                        minWidth: ' 200px',
                                        minHeight: '125px',
                                        display: ' flex',
                                        justifyContent: ' center',
                                        alignItems: 'center',

                                    }
                                }>
                               <Typography fontWeight={600}>And More...</Typography>
                            </Box>
                    </Container>
                </Box>
                <Box sx={{ background: '#FFFFFF', marginTop: '4rem' }}>
                    <Container>
                        <Box className='comparison_integration_head' sx={{ textAlign: 'center', padding: '2rem 0' }}>
                            <Typography variant='h4' fontWeight={700} marginBottom={2}>Comparison Integration</Typography>
                            <Typography>Add Delivery Services and Point of Sales to compare</Typography>
                        </Box>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                                <Box sx={{ backgroundColor: '#f1f5f9', padding: '1rem', borderRadius: 2, boxShadow: '2px 6px 14px rgba(0, 0, 0, 0.2)' }}>
                                    <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                        Orders Coming From
                                    </Typography>
                                    <Typography variant='h6' fontWeight={600} marginBottom={2}>
                                        Third Parties
                                    </Typography>
                                    <Typography marginBottom={2} >
                                        Integrating Third parties with your system will streamline orders, enhance efficiency, and ensure seamless operations, optimizing your food delivery service. By integrating Third Parties with your POS, you will have:
                                    </Typography>
                                    <Typography variant='h6' marginBottom={1} >
                                        Features:
                                    </Typography>
                                    <Typography>
                                        <ul>
                                            <li>Instant Onboarding Integration</li>
                                            <li>Automatically Accept Orders</li>
                                            <li>Manage Your Uber Eats Menu from dashboard.orderout.co</li>
                                            <li>Eliminate human error by avoiding manual entry of orders.</li>
                                            <li>Eliminate use of many Tablet</li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ display: 'flex' }}>
                                <Box sx={{ backgroundColor: '#f1f5f9', padding: '1rem', borderRadius: 2, boxShadow: '2px 6px 14px rgba(0, 0, 0, 0.2)' }}>
                                    <Typography variant='h5' fontWeight={600} marginBottom={2}>
                                        Orders going to
                                    </Typography>
                                    <Typography variant='h6' fontWeight={600} marginBottom={2} >
                                        POS
                                    </Typography>
                                    <Typography marginBottom={2}>
                                        Printhook's integrates third-party food delivery orders from services like UberEats, DoorDash, Grubhub, Grabull , Delivery.com , Ezcater, and Postmates directly into your POS system. We offer an absolutely FREE plan, allowing you to seamlessly integrate your Thir Parties orders into your POS system with no credit card required and same-day onboarding.
                                    </Typography>
                                    <Typography variant='h6' marginBottom={1} >
                                        Features:
                                    </Typography>
                                    <ul>
                                        <li>Integration with POS and Third Parties is FREE Forever!!!</li>
                                        <li>Instant onboarding and setup</li>
                                        <li> We are the best-rated delivery integration app on POS.</li>
                                        <li>No hardware is required, it will print automatically in your current printer setup</li>
                                        <li>Reduce labor expenses by eliminating manual data entry into the POS system</li>
                                    </ul>

                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }} marginTop={4} paddingBottom={4}>
                            <Button variant='contained'
                                sx={{
                                    backgroundColor: '#818cf8',
                                    color: '#FFFFFF',
                                    width: 'fit-content',
                                    borderRadius: 1,
                                    textTransform: 'none',
                                    '&:hover': {
                                        backgroundColor: '#818cf8', 
                                    }
                                }}
                                onClick={handleContactclick}
                            >Contact Us</Button>
                        </Box>
                    </Container>
                </Box>
                <Container >
                    <Box className='all_in_one_place'>
                        <Typography variant='h4' fontWeight={700} marginTop={4} marginBottom={2}>
                            All in one place
                        </Typography>
                        <Typography>
                            Increase productivity with a simple all-in-one app for managing online orders.
                        </Typography>
                    </Box>
                    <Grid container spacing={6} mt={4}>
                        <Grid item xs={12} md={6}>
                            <Grid container spacing={2}>
                                {
                                    services.map((service) => (
                                        <Grid item xs={6}>
                                            <Box
                                                sx={{
                                                    width: '100px',
                                                    height: '100px',
                                                    background: `${service.backcolor}`,
                                                    borderRadius: '50px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginTop: '15px',
                                                    marginBottom: '15px'
                                                }}
                                            >
                                                <img src={service.img} style={{ width: '50px' }} />
                                            </Box>
                                            <Typography fontWeight={700}>{service.heading}</Typography>
                                            <Typography>
                                                {service.peragraph}</Typography>
                                        </Grid>
                                    ))
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InquiryFrom />
                        </Grid>
                    </Grid>
                </Container>
            </Box >

        </ResourceLayout >
    )
}

export default Thirdparties_order_integration