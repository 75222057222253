import React, { useEffect, useState } from 'react'
import Navbar from '../components/navbar/Navbar'
import Footer from '../components/footer/Footer'
import Loading from '../components/loading/Loading';
export default function ResourceLayout({ children , show_navbar ,option}) {
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  })

  if (loading) {
    return <Loading fullHeight={true} />
  }
  return (
    <div>
      <Navbar redirectNav = {show_navbar} option = {option} logo_click = {true}/>
      <main>
        {children}
      </main>
      <Footer redirectFooter = {true}/>
    </div>
  )
}
