import React, { useEffect, useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import Home from '../home/Home';
import { Element } from 'react-scroll';
import { useSelector } from 'react-redux';
import FAQ from '../FAQ/FAQ';
import About from '../about/About';
import Footer from '../../components/footer/Footer';
import Pricingpage from '../pricing_info/Pricingpage';
import Brand_animation from '../../components/brands_animation/Brand_animation';
import Hover_scroll from '../hover_scroll_img/Hover_scroll';
import Blog from '../blog/Blog';
import Menu_control from '../menu_control/Menu_control';
import Virtual_kitchen from '../virtual_kitchen/Virtual_kitchen';
import Clover_Line from '../pos_integration_page/Clover_Line';
import { selectChoice } from '../../redux/navbarSlice';
import Loading from '../../components/loading/Loading';
function Main() {
  const choice = useSelector(selectChoice);
  const [loading , setLoading] = useState(true)
  const [currentSection, setCurrentSection] = useState('Home');
  let scrolling = false;

  useEffect(() => {
   
    const element = document.getElementById(choice);

      setTimeout(() => {
        setLoading(false);
      }, 1000);

    if (choice === 'home') {
      window.scrollTo(0, 0);
    }
    else
    {
      
      if (element) {
            const rect = element.getBoundingClientRect();
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const top = rect.top + scrollTop;
            window.scrollTo(0, top)
          }
    }
      }, [choice , loading])

  const debounce = (func, delay) => {
    let timeout;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args);
      }, delay);
    };
  };

  const updateCurrentSection = () => {
    const scrollPosition = window.scrollY;
    const homeElement = document.getElementById('home');
    const pricingElement = document.getElementById('pricing');
    const aboutElement = document.getElementById('about');
    const faqElement = document.getElementById('faq');
    const blogElement = document.getElementById('blog');
    const menu_controlElement = document.getElementById('menu_control');
    const virtual_kitchen = document.getElementById('virtual_kitchen');
    const brand_animation = document.getElementById('brand_animation');
    const hover_scroll = document.getElementById('hover_scroll');

    const threshold = 10;


    const elementsToCheck = [homeElement, pricingElement, menu_controlElement, virtual_kitchen, brand_animation, aboutElement, hover_scroll, blogElement, faqElement];
    const sectionNames = ['Home', 'Pricing', 'Menu control', 'Virtual kitchen', 'Brand animation', 'Who we are?', 'Hover scroll', 'Blog', 'FAQs'];

    elementsToCheck.forEach((element, index) => {
      if (element && (scrollPosition + 100) >= element.offsetTop - threshold) {
        setCurrentSection(sectionNames[index] || 'Other');
      }
    });
  };

  const debouncedUpdate = debounce(updateCurrentSection, 50);
  window.addEventListener('scroll', () => {
    if (!scrolling) {
      scrolling = true;
      debouncedUpdate();
      requestAnimationFrame(() => {
        scrolling = false;
      });
    }
  });

  if (loading) {
        return <Loading fullHeight={true} />
  }
  return (
    <div className='main_homr_page'>
      <Navbar currentSection = {currentSection} />
      <Element name="home" id='home'>
        <Home />
      </Element>
      <Element>
        <Clover_Line/>
      </Element>
      <Element name="pricing" id='pricing'>
        <Pricingpage />
      </Element>
      <Element name='menu_control' id='menu_control'>
        <Menu_control/>
      </Element>
      <Element name='virtual_kitchen' id='virtual_kitchen'>
        <Virtual_kitchen/>
      </Element>
      <Element name='brand_animation' id='brand_animation'>
        <Brand_animation/>
      </Element>
      <Element name='about' id='about'>
        <About/>
      </Element>
      <Element name='hover_scroll' id='hover_scroll'>
        <Hover_scroll/>
      </Element>
      <Element name = "blog" id='blog'>
        <Blog/>
      </Element>
      <Element name="faq" id='faq'>
        <FAQ/>
      </Element>
      <Footer/>
    </div>
  );
}

export default Main;
