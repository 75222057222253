import React from 'react'
import './Home.css'
import Section from '../../components/section/Section'
function Home() {
  return (
    <div className='home_page' id='home_page'>
      <Section/>
    </div>
  )
}

export default Home
