import React from 'react'
import About_menu_control from '../../components/about_menu_control/About_menu_control'

function Menu_control() {
    const pera1 = `<span>Printhook's</span> centralizes menu updates across delivery platforms, ensuring efficient, steady modifications. With a user-friendly interface, easily management of items, prices, and descriptions for a seamless customer experience, this convenience enables you to remain focused on delivering an exceptional dining experience to your customers.`
  return (
    <div>
      <About_menu_control heading = 'Simplified Menu Control' pera1 = {pera1} img = {'/assets/img/SimplifiedMenuControl.svg'}/>
    </div>
  )
}

export default Menu_control
