import './App.css';
import Main from './pages/main/Main';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PurchasePlan from './pages/purchase-plan/PurchasePlan';
import CustomPaln from './pages/custom_plan/CustomPaln';
import store from './redux/store';
import { Provider } from 'react-redux';
import Pricing from './pages/pricing/Pricing';
import PaymentSuccess from './pages/payment_success/PaymentSuccess';
import PaymentFail from './pages/payment_faliure/PaymentFail';
import Blog_page from './pages/blog_page/Blog_page';
import PosIntegration from './pages/pos_integration_page/Pos_integration_page';
import Blog_detail from './pages/blog_page/Blog_detail';
import Product_featurs from './pages/product_featurs/Product_featurs';
import Single_page from './components/single_page/Single_page';
import Contact from './pages/contact/Contact';
import Privacy_policy from './pages/privacy_policy/Privacy_policy';
import Terms from './pages/terms/Terms';
import Order_managment_page from './pages/order_managment_platform/order_managment_page';
import Centralized_order_tracking from './pages/centralized_order_tracking/centralized_order_tracking';
import Food_delivery_aggregator_Platform from './pages/food_delivery_aggregator_Platform/food_delivery_aggregator_Platform';
import Restaurant_delivery_management from './pages/restaurant_delivery_management/restaurant_delivery_management';
import Multi_channel_order_monitoring from './pages/multi_channel_order_monitoring/multi_channel_order_monitoring';
import Franchise_order_handling from './pages/franchise_order_handling/franchise_order_handling';
import Streamlined_delivery_operations from './pages/streamlined_delivery_operations/streamlined_delivery_operations';
import Efficient_order_management from './pages/efficient_order_management/efficient_order_management';
import Integrated_delivery_solutions from './pages/integrated_delivery_solutions/integrated_delivery_solutions';
import Real_time_order_updates from './pages/real_time_order_updates/real_time_order_updates';
import Menu_optimization_tools from './pages/menu_optimization_tools/menu_optimization_tools';
import Automated_order_fulfillment from './pages/automated_order_fulfillment/automated_order_fulfillment';
import Seamless_delivery_coordination from './pages/seamless_delivery_coordination/seamless_delivery_coordination';
import Customizable_order_workflows from './pages/customizable_order_workflows/customizable_order_workflows';
import Customer_support_services from './pages/customer_support_services/customer_support_services';
import Error404page from './pages/404/Error404page';
import Afterconfirm from './pages/afterconfirm/Afterconfirm';
import Thirdparties_order_integration from './pages/Thirdparties_order_integration/Thirdparties_order_integration';
import { Helmet } from 'react-helmet';
import Mobile_app_privscy_policy from './pages/privacy_policy/Mobile_app_privscy_policy';

function App() {
  return (
    <Provider store={store}>
      <div className='App'>
        <Helmet>
          <title>Printhooks</title>
        </Helmet>
        <BrowserRouter>
          <Routes>
            <Route exact path='/' element={<Main />} />
            <Route path='/pricing-plan' element={<Pricing />} />
            <Route path='/purchase-plan/:plan_id' element={<PurchasePlan />} />
            <Route path='/custom_plan' element={<CustomPaln />} />
            <Route path='payment/success' element={<PaymentSuccess />} />
            <Route path='payment/failure' element={<PaymentFail />} />
            <Route path='/resource/blog-page' element={<Blog_page />} />
            <Route path='/pos-integration-page' element={<PosIntegration />} />
            <Route path='/blog/*' element={<Blog_detail />} />
            <Route path='/product-featurs' element={<Product_featurs />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/privacy' element={<Privacy_policy />} />
            <Route path='/app-privacy-policy' element={<Mobile_app_privscy_policy />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/request-sended' element={<Afterconfirm />} />
            <Route path='3rd-party-order-intigrations-with-pos' element={<Thirdparties_order_integration/>}/>
            <Route
              path='/order-management-platform'
              element={<Order_managment_page />}
            />
            <Route
              path='/centralized-order-tracking'
              element={<Centralized_order_tracking />}
            />
            <Route
              path='/food-delivery-aggregator'
              element={<Food_delivery_aggregator_Platform />}
            />
            <Route
              path='/restaurant-delivery-management'
              element={<Restaurant_delivery_management />}
            />
            <Route
              path='/multi-channel-order-monitoring'
              element={<Multi_channel_order_monitoring />}
            />
            <Route
              path='/franchise-order-handling'
              element={<Franchise_order_handling />}
            />
            <Route
              path='/streamlined-delivery-operations'
              element={<Streamlined_delivery_operations />}
            />
            <Route
              path='/efficient-order-management'
              element={<Efficient_order_management />}
            />
            <Route
              path='/integrated-delivery-solutions'
              element={<Integrated_delivery_solutions />}
            />
            <Route
              path='/real-time-order-updates'
              element={<Real_time_order_updates />}
            />
            <Route
              path='/menu-optimization-tools'
              element={<Menu_optimization_tools />}
            />
            <Route
              path='/automated-order-fulfillment'
              element={<Automated_order_fulfillment />}
            />
            <Route
              path='/seamless-delivery-coordination'
              element={<Seamless_delivery_coordination />}
            />
            <Route
              path='/customizable-order-workflows'
              element={<Customizable_order_workflows />}
            />
            <Route
              path='/customer-support-services'
              element={<Customer_support_services />}
            />
            <Route path='*' element={<Error404page />} />
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
