import React, { useEffect, useState } from 'react'
import UserInfo from '../../components/userinfo/UserInfo'
function PersonalInfo(props) {
    const [personalData, setPersonalData] = useState({
        first_name: props.previosPersonalData === null ? '': props.previosPersonalData.first_name,
        last_name: props.previosPersonalData === null ? '': props.previosPersonalData.last_name,
    })
    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
    });
    const handleSubmit = (event) => {
        props.onNext(personalData);
    };
    const handleOnChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;
        // Validation
        if (fieldName === 'first_name' && fieldValue.length < 3) {
            setErrors({
                ...errors,
                first_name: 'First name must be at least 3 characters long.',
            });
        } else if (fieldName === 'last_name' && fieldValue.length < 3) {
            setErrors({
                ...errors,
                last_name: 'Last name must be at least 3 characters long.',
            });
        } else {
            setErrors({
                ...errors,
                [fieldName]: ''
            });
        }
        setPersonalData((prevData) => ({
            ...prevData,
            [fieldName]: fieldValue,
        }));
    };
    const isNextDisabled =
        errors.first_name || errors.last_name ||
        personalData.first_name.trim() === '' || personalData.last_name.trim() === '';
    return (
        <>
            <UserInfo imgUrl='/assets/img/PersonalInformation.svg' onSubmit={handleSubmit} headingText="Personal Information" h1 = "personal">
                <div className="info-form-group">
                    <input type="text" value={personalData.first_name} placeholder="First Name" id="first_name" name='first_name' onChange={handleOnChange} />
                    <div className="error-message">{errors.first_name}</div>
                </div>
                <div className="info-form-group">
                    <input type="text" value={personalData.last_name} placeholder="Last Name" id="last_name" name='last_name' onChange={handleOnChange} />
                    <div className="error-message">{errors.last_name}</div>
                </div>
                <div className='next_button'>
                    <button className={isNextDisabled ? 'disabled' : 'info-cta'} type='submit' disabled={isNextDisabled}>Next</button>
                </div>
            </UserInfo>
        </>
    );
}
export default PersonalInfo;