import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import ResourceLayout from '../../layout/ResourceLayout'
const Error404page = () => {

  const navigate = useNavigate();
  const handleGotoHomepageClick = () =>{
     navigate('/');
  } 
  return (
    <ResourceLayout show_navbar={true}>
      <Box sx={{ height: 'calc(100vh - 80px)', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop:'80px' }}>
        <Box sx={{ textAlign: 'center' }}>
          <Box>
            <Typography variant='h3' 
            sx={{
              fontWeight:600 ,
              fontSize:{
                xs:'2.5rem',
                lg:'3rem'
              }
            }}>Ooops... 404!</Typography>
            <Typography variant='h6' sx={{marginTop:'10px'}}>The page you requested could not be found.</Typography>
          </Box>
          <Box sx={{ height: '60vh' }}>
            <img src='/assets/img/404.svg' height='100%' width='100%' />
          </Box>
          <Box>
            <Button variant="outlined" sx={{ textTransform: 'none', marginTop:'15px', 
            fontSize:{
              xs:15,
              xl:18
            }
            ,fontWeight:500 , color:'#818CF8'}} onClick={handleGotoHomepageClick}>Go to Home Page</Button>
          </Box>
        </Box>
      </Box>
    </ResourceLayout>
  )
}

export default Error404page