import axios from "axios";
import RestaurantConfig from "./Restaurant_config";


class RestaurantSerivce
{
   createRestaurnt(restaurantData)
   {
      return new Promise((resolve , reject) =>{
        axios.post(RestaurantConfig.createRestaurant , restaurantData)
        .then((response) => {
            if (response) {
              resolve(response);
            } else {
              reject(response.data.error);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
   }
   
   confirmPaln(confirmData)
   {
    return new Promise((resolve , reject) =>{
        axios.post(RestaurantConfig.confirmPlan ,confirmData)
        .then((response) => {
            if (response) {
              resolve(response);
            } else {
              reject(response.data.error);
            }
          })
          .catch((error) => {
            reject(error);
          });
      })
   }

   fetchRestByID(rest_id)
   {
    return new Promise((resolove , reject) =>{
      axios.get(`${RestaurantConfig.fetchrestbyid}${rest_id}`)
      .then((response)=>{
        if (response) {
          resolove(response)
        } else {
          reject(response.data.error)
        }
      })
      .catch((error)=>{
        reject(error)
      })
    })
   }

   contact_details(contact_data)
   {
    return new Promise((resolve , reject) =>{
      axios.post(RestaurantConfig.createcontact , contact_data)
      .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    })
   }
}

const restaurantService = new RestaurantSerivce();
export default restaurantService;