import { configureStore } from "@reduxjs/toolkit";
import restaurantReducer from './restaurantSlice';
import otpReducer from './otpSlice';
import planReducer from './planSlice'
import navbarSlice from "./navbarSlice";
const store = configureStore({
    reducer:{
        restaurant: restaurantReducer,
        otp:otpReducer,
        plan: planReducer,
        navbar: navbarSlice
    },
});

export default store;