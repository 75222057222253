export let include_name = [
  'aaa',
  'bbb',
  'ccc',
  'ddd',
  'eee',
  'fff',
  'ggg',
  'hhh',
  'iii',
  'jjj',
  'kkk',
  'lll',
  'mmm',
  'nnn',
  'ooo',
  'ppp',
  'qqq',
  'rrr',
  'sss',
  'ttt',
  'uuu',
  'vvv',
  'www',
  'xxx',
  'yyy',
  'zzz',
];

export let alphabetArray = [
  'abcd',
  'bcdf',
  'cdef',
  'defg',
  'efgh',
  'fghi',
  'ghij',
  'hijk',
  'ijkl',
  'jklm',
  'klmn',
  'lmno',
  'mnop',
  'nopq',
  'opqr',
  'pqrs',
  'qrst',
  'rstu',
  'stuv',
  'tuvw',
  'uvwx',
  'vwxy',
  'wxyz',
];

export const mobileSpamPatterns = [
  '1234567890',
  '0987654321',
  '1111111111',
  '2222222222',
  '3333333333',
  '4444444444',
  '5555555555',
  '6666666666',
  '7777777777',
  '8888888888',
  '9999999999',
  '1212121212',
  '1313131313',
  '1414141414',
  '1515151515',
  '1616161616',
  '1717171717',
  '1818181818',
  '1919191919',
  '0000000000',
  '0101010101',
  '0202020202',
  '0303030303',
  '0404040404',
  '0505050505',
  '0606060606',
  '0707070707',
  '0808080808',
  '0909090909',
];
