import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';

function Restaurant_delivery_management() {
  const peras = [
    `Managing deliveries for your restaurant can be complex, but with Printhook's, it becomes effortless. Our restaurant delivery management platform is designed to streamline every aspect of your delivery operations, from order processing to delivery coordination.`,
    `Efficient order processing is crucial for ensuring customer satisfaction and timely deliveries. With Printhook's, you can automate order entry, track order status in real-time, and customize workflows to fit your specific needs. Say goodbye to manual errors and delays – Printhook's simplifies order processing for your restaurant.`,
    `Delivery coordination is key to ensuring smooth and timely deliveries. Printhook's optimizes delivery routes, assigns drivers efficiently, and provides real-time updates to keep you informed every step of the way. Our platform integrates seamlessly with various delivery partners, streamlining communication and coordination for your restaurant.`,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Simplify restaurant delivery management with Printhook's. From order processing to delivery coordination, optimize every aspect of your operations."
        />
        <meta
          name='keywords'
          content='restaurant delivery management, order processing, delivery coordination, streamline operations'
        />
        <title>Printhook's: Restaurant Delivery Management Made Easy</title>
      </Helmet>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading='Restaurant Delivery Management Made Easy'
        img1='/assets/img/useimg.png'
        peras={peras}
        navbar={false}
      >
        <div className='order_managment'>
          <p>
            Printhook's empowers you to take control of your restaurant delivery
            management. With our platform, you can track orders, manage
            deliveries, and optimize operations with ease. Whether you're a
            small independent restaurant or a large franchise operation,
            Printhook's has the tools you need to succeed.
          </p>

          <p>
            Printhook's empowers you to take control of your restaurant delivery
            management. With our platform, you can track orders, manage
            deliveries, and optimize operations with ease. Whether you're a
            small independent restaurant or a large franchise operation,
            Printhook's has the tools you need to succeed.
          </p>
        </div>
      </Single_page>
    </>
  );
}

export default Restaurant_delivery_management;
