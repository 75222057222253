import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectChoice, updateChoice } from '../../redux/navbarSlice';
const drawerWidth = 240;

function DrawerAppBar(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handlenavbuttonClick = () => {
    if (props.redirectNav) {
      navigation('/');
    }
    setMobileOpen(false)
  }

  const handleCustomerloginClick = () => {
    const newWindow = window.open(`${process.env.REACT_APP_DASHBORAD_URL}/sign-in`, '_blank');
    setTimeout(() => {
      const message = {
        action: 'clearlocalstorage',
      };
      newWindow.postMessage(message, '*');
    }, 300);
  };

  const handleLogoClick = (choice) => {
    console.log(choice);
    navigation('/')
    dispatch(updateChoice(choice));
  }

  // DEFINE ALL BUTTONS OF NAVBAR
  const navButtons = [
    { text: "Home", targetSection: "home_page" },
    { text: "Pricing", targetSection: "pricing_stop_scroll" },
    { text: "Who we are?", targetSection: "about_stop_scroll" },
    { text: "Blog", targetSection: "blog_stop_scroll" },
    { text: "FAQs", targetSection: "faq_stop_scroll" },
    { text: "Contact", targetSection: "contact_stop_scroll" }
  ];

  // DEFINE DRAWER FOR MOBILE
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>

      {navButtons.map((button, index) => (
        <Box>
          <NavButton
            key={index}
            text={button.text}
            targetSection={button.targetSection}
            currentSection={props.currentSection}
            handlenavbuttonClick={handlenavbuttonClick}
            redirectNav={props.redirectNav}
            option={props.option}
          />
        </Box>
      ))}
      <Button sx={{ color: '#877AFD', fontWeight: '700', fontSize: 16, textTransform: 'none' }} onClick={handleCustomerloginClick} id='myBTN'>
        Login
      </Button>

    </Box>
  );
  const container = undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      {/* NAVBAR IN NORMAL OR LARGE SCREEN SCREEN */}
      <AppBar component="nav" sx={{ height: '80px', justifyContent: 'center', backgroundColor: 'white' }}>
        <Toolbar>
          {props.redirectNav === false ? <></> : <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
          >
            <MenuIcon />
          </IconButton>}
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              display: { sm: 'block' },
              display: { xs: 'flex' },
              justifyContent: { xs: 'center', sm: 'start' },
              cursor: 'pointer'
            }}
          >
            {props.logo_click ? <>
              <a onClick={() => { handleLogoClick('home_page') }}><img width="120" src="/assets/img/PrintHookslogo.svg" alt="logo" /></a>
            </> :
              <ScrollLink
                to='home_page'
              >
                <img width="120" src="/assets/img/PrintHookslogo.svg" alt="logo" />
              </ScrollLink>
            }
          </Typography>
          {props.redirectNav === false ? <></> : <Box sx={{ display: { xs: 'none', sm: 'block' } }}>

            {navButtons.map((button, index) => (
              <NavButton
                key={index}
                text={button.text}
                targetSection={button.targetSection}
                currentSection={props.currentSection}
                handlenavbuttonClick={props.handlenavbuttonClick}
                redirectNav={props.redirectNav}
                option={props.option}
              />
            ))}

            <Button sx={{ color: '#877AFD', fontWeight: '700', fontSize: 16, marginLeft: '10px', textTransform: 'none' }} onClick={handleCustomerloginClick} >
              Login
            </Button>
          </Box>}
        </Toolbar >
      </AppBar >
      
      {/* DRAWER IN MOBILE  */}
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box >
  );
}
export default DrawerAppBar;

// NAVBUTTON COMPONENT OR FUNCTION
export function NavButton({ text, targetSection, currentSection, handlenavbuttonClick, redirectNav, option }) {
  const buttonStyle = {
    color: '#877AFD',
    fontWeight: '700',
    fontSize: 16,
    textTransform: 'none',
    marginLeft: '10px',
    cursor: 'pointer',
  };
  const domain = process.env.REACT_APP_FRONT_FLOW_URL;
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const choice = useSelector(selectChoice);
  const handlenoramlButtonClick = (text) => {

    if (text === 'Contact') {
      navigation('/contact');
      dispatch(updateChoice('Contact'));
    }
  }

  const handleRedirectNavbuttonClick = (choice) => {
    navigation('/')
    dispatch(updateChoice(choice));
  }

  return (
    <>
      {
        text === 'Contact' ? <Button style={buttonStyle} onClick={() => { handlenoramlButtonClick(text) }}>  <span style={{ transition: 'border-bottom 0.3s ease', borderBottom: text === option ? '2px solid #877AFD' : '2px solid transparent', padding: '0px' }} >{text}</span></Button> :
          <>
            {
              redirectNav ?
                <>
                  <Button style={buttonStyle} onClick={() => { handleRedirectNavbuttonClick(targetSection) }}>{text}</Button>
                </>
                :
                <ScrollLink
                  to={targetSection}
                >
                  <Button
                    style={buttonStyle}
                    onClick={handlenavbuttonClick}
                  >
                    <span style={{ transition: 'border-bottom 0.3s ease', borderBottom: text === currentSection ? '2px solid #877AFD' : '2px solid transparent', padding: '0px' }} >{text}</span>
                  </Button>
                </ScrollLink>
            }

          </>
      }
    </>

  );
}