

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedChoice: 'home'
};

const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    updateChoice(state, action) {
      state.selectedChoice = action.payload;
    }
  }
});

export const { updateChoice } = navbarSlice.actions;
export const selectChoice = (state) => state.navbar.selectedChoice;

export default navbarSlice.reducer;
