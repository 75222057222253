import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';

function Food_delivery_aggregator_Platform() {
  const peras = [
    `In the dynamic world of food delivery, efficient aggregation and management are essential for success. Printhook's offers a comprehensive food delivery aggregator platform designed to streamline your operations and maximize efficiency.`,
    `With Printhook's, you can centralize the aggregation of food delivery orders from various platforms and partners. Say goodbye to the hassle of managing multiple systems – Printhook's consolidates all your order information into one convenient dashboard.`,
    `Efficiency is at the core of Printhook's food delivery aggregator platform. Our intuitive interface allows you to manage orders seamlessly, ensuring prompt processing and delivery. Whether you're handling a high volume of orders or coordinating deliveries across multiple locations, Printhook's has you covered.`,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Streamline your food delivery operations with Printhook's. Centralized aggregation, efficient management, and seamless coordination. Try now!"
        />
        <meta
          name='keywords'
          content='food delivery aggregator, centralized platform, efficient management, seamless coordination'
        />
        <title>Printhook's: Food Delivery Aggregator Platform</title>
      </Helmet>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading='Food Delivery Aggregator Platform'
        img1='/assets/img/useimg.png'
        peras={peras}
        navbar={false}
      >
        <div className='order_managment'>
          <p>
            Centralized aggregation is just the beginning. Printhook's platform
            offers advanced features to optimize your food delivery operations.
            From route optimization to driver assignment, our tools empower you
            to deliver orders faster and more efficiently.
          </p>

          <p>
            Coordination is key to delivering a seamless food delivery
            experience. Printhook's streamlines communication between your
            restaurant and delivery partners, ensuring that orders are fulfilled
            promptly and accurately. Real-time updates keep you informed every
            step of the way, allowing you to provide exceptional service to your
            customers.
          </p>
          <p>
            Join the ranks of successful food delivery businesses that trust
            Printhook's to streamline their operations. Sign up today and
            discover how our food delivery aggregator platform can help you take
            your business to the next level. Simplify aggregation, optimize
            management, and deliver excellence with Printhook's.
          </p>
        </div>
      </Single_page>
    </>
  );
}

export default Food_delivery_aggregator_Platform;
