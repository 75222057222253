
import React, { useEffect, useState } from 'react'
import PricingCard from './PricingCard';
import { useDispatch, useSelector } from 'react-redux';
import { getAllPlan } from '../../redux/planSlice';
import { IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import CustomPaln from '../custom_plan/CustomPaln';
import useMediaQuery from '@mui/material/useMediaQuery';
import PurchasePlan from '../purchase-plan/PurchasePlan';
import Loading from '../../components/loading/Loading';
import { Checkbox } from '@mui/material'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { setReviewPageFalse, setReviewPageTrue } from '../../redux/otpSlice';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import data from '../../plandetails.json'

function Pricing_card_info(props) {
    const [toggle, setToggle] = useState('monthly');
    const [monthly_plan, setMonthly_plan] = useState([]);
    const [anually_plan, setAnually_plan] = useState([]);
    const [all_plan , setAll_plan] = useState([]);
    const [show_no_plan_message , setShow_no_plan_message] = useState(false);
    const [plan_id, setPlan_id] = useState(0);
    const [planTerm , setPlanTerm] = useState(0);
    const [planType , setPlantype] = useState(0);
    const [outlet, setOutlet] = useState(0)
    const [integration, setIntegration] = useState(0);
    const [plan_loading, setPlan_loading] = useState(true);
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const [customplan, setCustomplan] = useState(false);
    const [plan_details, setPlanDetails] = useState([]);
    const [selectedCards, setSelectedCards] = useState([]);
    const reviewPage = useSelector((state) => state.otp.reviewPage);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleToggleClick = (selectedOption) => {
        setToggle(selectedOption);
    };
    
    const dispatch = useDispatch();
    useEffect(() => {
        // GET ALL PLAN
        dispatch(getAllPlan())
            .unwrap()
            .then((response) => {
                if (response.data.length === 0) {
                    setShow_no_plan_message(true)
                }
                else{
                    setAll_plan(response.data);
                    const monthlyPlans = response.data.filter((plan) => plan.plan_term === 1);
                    const annuallyPlans = response.data.filter((plan) => plan.plan_term === 2);
                    setMonthly_plan(monthlyPlans);
                    setAnually_plan(annuallyPlans);
                    setPlan_loading(false);
                }  
             })
             .catch((error)=>{
                setShow_no_plan_message(true)
             })
        const plan_details = data.plans.Plan_details.filter((ele) => ele.term === 1 && ele.type === 1);
        setPlanDetails(plan_details)

    }, []);

    // handle open pricing page when click on pricing button in main page...
    useEffect(() => {
        if (props.plan_id === undefined) {
            setSelectedCards(toggle === 'monthly' ? [monthly_plan[0]] : [anually_plan[0]])
            const plan_details = data.plans.Plan_details.filter((ele) => ele.term === 1 && ele.type === 1);
            setPlanDetails(plan_details)
        }
    }, [monthly_plan , all_plan , toggle])

    // handle open pricing page when click on change plan button in review page..
    useEffect(() => {
        if (props.plan_id !== undefined) {
            setSelectedCards(all_plan.filter((ele) => ele.id === props.plan_id));
            const plan_details = data.plans.Plan_details.filter((ele) => ele.term === props.plan_term && ele.type === props.plan_type);
            setPlanDetails(plan_details)
            if (anually_plan.some(plan => plan.id === props.plan_id)) {
                handleToggleClick('annually');
            }

        }

    }, [anually_plan, monthly_plan]);

    // Handle Checkbox click...
    const handleCheckboxClick = (id , name , plan_term , plan_type) => {
        if (selectedCards[0].id === id) 
        {
            // Set Basic pricing card if clicked card is already is selected..
            setSelectedCards(toggle === 'monthly' ? [monthly_plan[0]] : [anually_plan[0]]);
            const plan_details = data.plans.Plan_details.filter((ele) => ele.term === 1 && ele.type === 1);
            setPlanDetails(plan_details)
        } 
        else 
        {
            // Select pricing card if card is not selected....
            setSelectedCards(all_plan.filter((ele) => ele.id === id));
            const plan_details = data.plans.Plan_details.filter((ele) => ele.term === plan_term && ele.type === plan_type);
            setPlanDetails(plan_details)
        }
    };

    // Handle Subscribe Button...
    const handleSubscribButton = () => {
        setPlan_id(selectedCards[0].id);
        setPlanTerm(selectedCards[0].plan_term);
        setPlantype(selectedCards[0].plan_type);
        if (props.plan_id !== undefined) {
            Swal.fire({
                title: ` Are you sure you want to change your plan ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Confirm',
                cancelButtonText: 'Cancel',
                customClass: {
                    title: 'custom-title-class',
                    confirmButton: 'swal2-confirm ',
                    cancelButton: 'custom-cancel-button-class',
                    popup: 'show_alert'
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    props.changed_palan_id(selectedCards[0].id , selectedCards[0].plan_term , selectedCards[0].plan_type)
                    dispatch(setReviewPageTrue())
                }
            });
        }
        else {
            setOpen(true);
        }
        // SET OUTLET AND INTEGRATION
        if (selectedCards[0].plan_term === 1) {
            const monthplan = monthly_plan.filter((plan) => plan.id === selectedCards[0].id)
            setOutlet(monthplan[0].max_outlets);
            setIntegration(monthplan[0].max_integrations);
        }
        else {
            const yearplan = anually_plan.filter((plan) => plan.id === selectedCards[0].id)
            console.log(yearplan);
            setOutlet(yearplan[0].max_outlets);
            setIntegration(yearplan[0].max_integrations);
        }

        // Open custom from...
        if (selectedCards.includes('custom')) {
            setCustomplan(true)
        }
    }
    const handleClose = () => {
        setOpen(false);
        setTimeout(() => {
            setCustomplan(false);
            dispatch(setReviewPageFalse())
        }, 500);
    };

    const changed_palan_id = (id) => {
        props.changed_palan_id(id)
    }
    const handleTailored_priceclick = () => {
        setOpen(true);
        setCustomplan(true)
    }

    // IF PLAN IS NOT DEFINE THEN SHOW NO PLAN IMAGE
    if (show_no_plan_message) {
        return <No_Plan/>
    }
    if (monthly_plan.length ==0 && !show_no_plan_message) {
        return <Loading />
    }
    if (props.plan_loading && plan_loading) {
        return <Loading />
    }
        return (
        <div>
            <div className={`pricing_container ${props.plan_id !== undefined && 'pricing_container_center'}`}>
                <div className='pricing_left' data-aos="flip-left" data-aos-duration="500">
                    <div className='pricing_left_toggle'>
                        <div
                            className={`toggle-left ${toggle === 'monthly' ? 'toggle_back_color' : ''}`}
                            onClick={() => handleToggleClick('monthly')}
                        >
                            Monthly
                        </div>
                        <div
                            className={`toggle-right ${toggle === 'annually' ? 'toggle_back_color' : ''}`}
                            onClick={() => handleToggleClick('annually')}
                        >
                            Annually
                        </div>
                    </div>
                    <div className='pricing_cards'>
                        {toggle === 'monthly' ?
                            monthly_plan.map((plan, index) => (
                                <PricingCard
                                    key={plan.id}
                                    id={plan.id}
                                    plan_id ={props.plan_id}
                                    title={plan.title}
                                    price={plan.price}
                                    name = {plan.name}
                                    plan_term = {plan.plan_term}
                                    plan_type = {plan.plan_type}
                                    is_month={true}
                                    selected={selectedCards.includes(plan)}
                                    handleCheckboxClick={handleCheckboxClick}
                                    selected_id={props.plan_id}
                                    changed_palan_id={changed_palan_id}
                                />
                            )) :
                            anually_plan.map((plan, index) => (
                                <PricingCard
                                    key={plan.id}
                                    id={plan.id}
                                    plan_id ={props.plan_id}
                                    title={plan.title}
                                    price={plan.price}
                                    name = {plan.name}
                                    plan_term = {plan.plan_term}
                                    plan_type = {plan.plan_type}
                                    is_month={false}
                                    selected={selectedCards.includes(plan)}
                                    handleCheckboxClick={handleCheckboxClick}
                                    selected_id={props.plan_id}
                                />
                            ))
                        }
                    </div>
                    {!props.custom_not_show && <div className='tailored_pricing' >
                        <button onClick={handleTailored_priceclick}>
                            Tailored Pricing
                        </button>
                    </div>}
                </div>
                <div className='pricing_right' data-aos="flip-right" data-aos-duration="500">
                    <div className='pricing_description'>
                        <ul>
                            {plan_details.map((plan, index) => (
                                <div key={index}>
                                    <h2>{plan.name}</h2>
                                    {plan.details.map((detail, idx) => (
                                        <li key={idx}>
                                           { detail !== "" && <span><img src='/assets/img/Vector.svg' height={'15px'} /></span> }<span dangerouslySetInnerHTML={{ __html: detail }} id='price_detail' /> 
                                        </li>
                                    ))}
                                </div>
                            ))}
                        </ul>

                    </div>
                    <div className='subscrip_button'>
                        {selectedCards.length !== 0 ? <button onClick={handleSubscribButton}>
                            SUBSCRIBE
                        </button> :
                            <div className='disabled_sub_button'>
                                SUBSCRIBE
                            </div>}
                    </div>
                </div>
            </div>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth
                fullHeight
                maxHeight='lg'
                className='information_dialog'
                maxWidth={fullScreen || reviewPage ? 'sm' : 'lg'}
            >
                {customplan ? <CustomPaln setOpen={setOpen} /> : <PurchasePlan plan_id={plan_id} plan_term = {planTerm} plan_type = {planType} integration={integration} outlet={outlet} />}
                <div style={{ position: 'absolute', right: '8px', top: '8px', cursor: 'pointer' }}>
                    <IconButton>
                        <CloseIcon onClick={handleClose} />
                    </IconButton>
                </div>
            </Dialog>
        </div>
    )
}

export default Pricing_card_info

function No_Plan() {
    return (
        <>
        <div className='no_plan_image'>
         <img src='/assets/img/noplan.svg'/>
        </div>
        </>
    )
}
