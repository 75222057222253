


import React from 'react'
import './Loading.css'
function Loading(props) {
  return (
    <div className='loading_page' style={{height:`${props.fullHeight === true ? '100vh' : ''}`}}>
      <div className="spinner-box">
        <div className='loading_logo'>
        <img width="128" src="/assets/img/PrintHookslogo.svg" alt="logo" />
        </div>
  <div className="pulse-container">  
    <div className="pulse-bubble pulse-bubble-1"></div>
    <div className="pulse-bubble pulse-bubble-2"></div>
    <div className="pulse-bubble pulse-bubble-3"></div>
  </div>
</div>
    </div>
  )
}

export default Loading
