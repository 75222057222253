import React, { useEffect, useState } from 'react'
import UserInfo from '../../components/userinfo/UserInfo'
import { useSelector } from 'react-redux'
import { Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Pricing from '../pricing/Pricing';
import { useDispatch } from 'react-redux';
import { changePlan, getPlanById, payment } from '../../redux/planSlice';
import { confirmPlan, fetchRestById, setpreventScroll } from '../../redux/restaurantSlice';
import { ToastContainer, toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import Swal from 'sweetalert2';
import { setReviewPageTrue } from '../../redux/otpSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Pricing_card_info from '../pricing_info/Pricing_card_info';
import LoadingButton1 from '../../components/button/LoadingButton1';
import { useNavigate } from 'react-router-dom';

function ReviewDetail(props) {

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [change_plan, setChange_plan] = useState(0);
  const [change_term , setChange_term] = useState(0);
  const [change_type , setChange_type] = useState(0);
  const [purchase_loading, setPurchase_loading] = useState(false);
  const [purchased, setPurchased] = useState(false)
  const [buttonloading , setButtonloading] = useState(false)
  const [plan, setPlan] = useState({});
  const navigation = useNavigate();

  useEffect(() => {
    dispatch(setReviewPageTrue());
    dispatch(setpreventScroll(true));
    dispatch(getPlanById(props.plan_id))
      .unwrap()
      .then((response) => {
        setPlan(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }, [])
  const handleChangePlan = () => {
    console.log('plan Change');
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
    dispatch(setReviewPageTrue())
  };

  const changed_palan_id = (id , term , type) => {
        setChange_plan(id);
        setChange_term(term);
        setChange_type(type)
    dispatch(changePlan({ inquiry_id: props.restData.id, plan_id: id }))
      .unwrap()
      .then((response) => {
        if (response.status == 200) {
          dispatch(getPlanById(id))
            .unwrap()
            .then((response) => {
              setPlan(response.data);
            })
        }
      })
      .catch((error) => {
        console.log(error);
      })
    setOpen(false)
  }


  const clickConfirmButton = () => {
    setPurchase_loading(true);
    setButtonloading(true)
    const selectedPlan = change_plan === 0 ? props.plan_id : change_plan;

    // GENERATE STRAIP LINK
    // dispatch(payment({plan_id:selectedPlan,inquiry_id: props.restData.id }))
    //   .unwrap()
    //   .then((paymentResponse) => {

        // const successUrl = paymentResponse.data.plan_stripe_url;

        // let a = document.createElement('a');
        // a.href = successUrl;
        // a.click();

      // })
      // .catch((paymentError) => {
      //   console.log(paymentError);
      //   setPurchase_loading(false);
      // });

      setTimeout(() => {
        setButtonloading(false);
        navigation('/request-sended')
      }, 1500);
  };

  return (
    <div>
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <UserInfo
        headingText="Review Details">
        {/* <div className='first_row_review'>
          <input type="text" placeholder="First Name" value={props.personalData.first_name} id="first_name" name='first_name' readOnly />
          <input type="text" placeholder="Last Name" value={props.personalData.last_name} id="last_name" name='last_name' readOnly />
        </div> */}
        <div className='second_row_review'>
          <input type="text" placeholder="Restaurant Name" value={props.restData.restaurant_name} id="restaurant_name" name='name' readOnly />
        </div>
        <div className='third_row_review'>
          <input type="text" placeholder="Email" value={props.restData.email} id="email" name='email' readOnly />
          <input type="text" placeholder="Phone number" value={props.restData.phone_number} id="phone" name='phone' readOnly />
        </div>
        <div className='forth_row_review'>
          <input type="text" placeholder="Address" value={props.restData.restaurant_address} id="address" name='address' readOnly />
        </div>
        <div className='pricing_plan_box'>
          <div className='pricing_plan_box_heading'>{plan.title}</div>
          <div className='pricing_plan_box_outlet'>{plan.max_outlets} {plan.max_outlets === 1 ? 'Store' : 'Stores'}</div>
          <div className='pricing_plan_box_price'>{plan.id === 1 || plan.id === 2 || plan.id === 3 || plan.id === 4 ? `$${plan.price}/Month` : `$${plan.price}/Year`}</div>
          <div className='pricing_plan_box_chang-plan' onClick={handleChangePlan}>Change Plan</div>
        </div>
        <div className='review_confirm_button'>
          <LoadingButton1
          buttonloading={buttonloading}
          text='Confirm'
          onClick={clickConfirmButton}
          sx={{
            paddingY:'6px',
            paddingX:'70px',
            fontSize:'1rem',
          }}
          />
        </div>
      </UserInfo>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        fullHeight
        maxHeight='lg'
        className='pricing-dialog'
        maxWidth={'xl'}

      >
        <Pricing_card_info plan_id={change_plan === 0 ? props.plan_id : change_plan} plan_term = {change_term === 0 ? props.plan_term : change_term} plan_type = {change_type === 0 ? props.plan_type : change_type} changed_palan_id={changed_palan_id} selected={true} plan_loading={true} custom_not_show={true} />
        <div style={{ position: 'absolute', right: '5px', top: '5px', cursor: 'pointer' }}>
          <IconButton>
            <CloseIcon onClick={handleClose} />
          </IconButton>
        </div>
      </Dialog>
    </div>
  )
}

export default ReviewDetail
