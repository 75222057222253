import Dialog from '@mui/material/Dialog';
import React, { useState } from 'react';
import Purchaseplan from '../purchase-plan/PurchasePlan'
import CustomPlan from '../custom_plan/CustomPaln'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { setpreventScroll } from '../../redux/restaurantSlice';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { setReviewPageFalse, setReviewPageTrue } from '../../redux/otpSlice';
import { IconButton } from '@mui/material';
const Pricingcard = ({ id , title, desc ,outlet, integration, price, index , cards_array , selected ,  changed_palan_id , selected_id}) => {
  const [open, setOpen] = useState(false);
  const [customplan , setCustomplan] = useState(false);
  const [plan_id , setPlan_id] = useState()
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const reviewPage = useSelector((state) => state.otp.reviewPage);
  const clickBuyNow = (id, event) => {
    event.stopPropagation();
    dispatch(setpreventScroll(true));
    setOpen(true);
    setPlan_id(id);
    if (id === 'custom') {
      setCustomplan(true)
    }
  };
  const handleClose = () => {
    dispatch(setpreventScroll(false));
    setOpen(false);
    setTimeout(() => {
      dispatch(setReviewPageFalse())
    }, 1000);
  };

  const handleSelect = (id)=>{
    if (id === 'custom') {
      setOpen(true)
      setCustomplan(true)
      dispatch(setReviewPageFalse());
    }
    if(id !== selected_id && id !== 'custom')
    {
      Swal.fire({
        title: ` Are you sure you want to change your plan ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          title: 'custom-title-class',
          confirmButton: 'swal2-confirm ',
          cancelButton: 'custom-cancel-button-class',
          popup:'show_alert'
        },
      }).then((result) => {
          if(result.isConfirmed)
          {
             changed_palan_id(id)
             dispatch(setReviewPageTrue())
          }
      });
    }

  }
  return (
    <>
      <div className={`card shadow ${id === selected_id ? 'active':''}`}
        style={{
          marginLeft: index === 0 ? '0' : '-5px',
          zIndex: id === selected_id ? '900':cards_array.length - index,
        }}
        onMouseEnter={(e) => {
          if (id !== selected_id) {
            e.currentTarget.style.transform = 'scale(1.1)';
            e.currentTarget.style.zIndex = '1000';
          } 
        }}
        onMouseLeave={(e) => {
          if (id !== selected_id) {
            e.currentTarget.style.transform = 'scale(1)';
            e.currentTarget.style.zIndex = cards_array.length - index;
          }
        }}
        >
        <div className="pack">{title}</div>
        <div className='card_info'>
          <h3>{id === 'custom'? '': desc}</h3>
          <div className='outlet_integration_info'>
            <h3>{outlet} {id === 'custom' ? 'Limit-free Outlets ' : (outlet === 1 ? 'Outlet':'Outlets')}</h3>
            <h3>{integration}  {id === 'custom' ? 'Endless integration' : 'Integrations'}</h3>
          </div>
          <div className='price'>
            {id === 1 || id ===2 || id === 3 ? `$${price.match(/\d+/)[0]}/Month`:(id ===4 || id ===5 ||id ===6 ? `$${price.match(/\d+/)[0]}/Year`: `${price}`)}
          </div>
          { !selected ?<button className='btn' onClick={(event) => clickBuyNow(id, event)}>
            {id=== 'custom'?'Contact us':'Buy Now'}
          </button> :<button className='btn' onClick={() => handleSelect(id)}>{id === selected_id ? 'Selected':'Select'}</button>}
        </div>

      </div>
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        fullHeight
        maxHeight='lg'
        className='information_dialog'
        maxWidth={fullScreen || reviewPage ? 'sm' : 'lg'}
     >
        {customplan ? <CustomPlan setOpen={setOpen}/> : <Purchaseplan plan_id={plan_id} integration={integration} outlet={outlet}/>}
        <div style={{ position: 'absolute', right: '6px', top: '8px', cursor: 'pointer' }}>
          <IconButton>
          <CloseIcon onClick={handleClose}/>
          </IconButton>
          {/* <CloseIcon onClick={handleClose} sx={{fontSize:'18px' , borderRadius:'50%' , boxShadow:'0px 0px 5px gray' , fontWeight:'600'}}/> */}
        </div>
      </Dialog>
    </>

  );
};

export default Pricingcard;
