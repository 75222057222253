const data = [
    {
        id:1,
        question :`How will I manage the orders coming in my clover from so many different apps?`,
        ans:`Printhook's already has it managed for you. By taking advantage of our POS digital parking
        integration, we send every order, no matter which app it originated from, directly to your POS
        system into to your restaurant with brand name Identification. It’s all in seamless in one place.`   
    },
    {
        id:2,
        question :`How do I know the orders that come in through this system don't have errors?`,
        ans:`No one on your staff, or anywhere else, will need to input the orders manually. So there is no potential for human error to be a factor. Whatever the customer orders, it will replicate exact same order data directly to your POS screen, and it’s what your restaurant staff will see.`    
    },
    {
        id:3,
        question :`How long do the integrations take to set up?`,
        ans:`For most restaurants, you should anticipate roughly five to seven business days to fully setup,
        test and configure your Printhook's integration. However, some qualified restaurants are up and
        running on the very second day. `    
    },
    {
        id:4,
        question :`How do I know if Printhook's is right for my business needs?`,
        ans:`If any aspect of your ordering, payments, or delivery workflow is fragmented, Printhook's simply
        help integrate these steps into a single, unified process. For example, many restaurants now
        offer online ordering options that exist separately from in-store ordering. The same goes for
        third-party delivery services such as DoorDash, Grubhub and Uber Eats. Printhook's can help
        automate these tasks and remove the need for manual data entry. In simple words if you want
        convenience, easy Instore operations for all online orders in POS Printshook's is for you.`    
    },
]
export default data;