
import React, { useEffect , useRef } from 'react'
import AfterPayment from '../../components/after_payment/AfterPayment'
import { useNavigate } from 'react-router-dom';
import lottie from 'lottie-web';
import animationData from './payment _successful.json';
function PaymentSuccess() {
  const navigate = useNavigate(); 
  useEffect(()=>{
    setTimeout(() => {
      navigate('/')
    }, 5000);
  },[])

  const textContent = (
    <>
     Thank you for subscribing to {' '}
      <span>Printhook's</span>.This is a confirmation message for your payment. A copy of a detailed invoice can be found on your registered email.
    </>
  );
  return (
   <>
   <AfterPayment text = {textContent} image = "/assets/img/payment _successful.json"/>

   </>
  ) 

}

export default PaymentSuccess
