import axios from 'axios';
import blogConfig from './Blog_config';

class BlogsService {
  listofblog(url) {
    let final_url = blogConfig.allblog;
    if (url != undefined) {
      final_url = final_url + url;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(final_url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getdetailsblog(url) {
    let final_url = blogConfig.allblog;
    if (url != undefined) {
      final_url = final_url + url;
    }
    return new Promise((resolve, reject) => {
      axios
        .get(final_url, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
        .then((response) => {
          console.log(response);
          if (response) {
            resolve(response.data);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

const blogsService = new BlogsService();
export default blogsService;
