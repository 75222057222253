import React, { useEffect, useState } from 'react';
import PersonalInfo from '../personalinfo/PersonalInfo';
import RestaurantInfo from '../restaurantinfo/RestaurantInfo';
import Otp_Verification from '../otp-verification/Otp_Verification';
import ReviewDetail from '../reviewdetail/ReviewDetail';

function PurchasePlan(props) {
  const [step, setStep] = useState(1);
  const [personalData, setPersonalData] = useState(null);
  const [restaurantData, setRestaurantData] = useState(null);
  const [previosPersonalData , setPreviosPersonalData] = useState(null);

  const handleNext = (data) => {
    if (step === 1) {
      setRestaurantData(data);
    }
      setStep(step + 1);
  };

  const handlePerivios = (data) =>{
   
    if (step === 2) {
      setPreviosPersonalData(data);
    }
    setStep(step-1)
  }
  return (
    <div>
      {step === 1 && <RestaurantInfo plan_id={props.plan_id} onNext={handleNext} onPrevious = {handlePerivios} integration={props.integration} outlet={props.outlet}/>}
      {/* {step === 2 && <Otp_Verification onPrevious = {handlePerivios} restData = {restaurantData} plan_id ={props.plan_id} onNext={handleNext}/> } */}
      {step === 2 && <ReviewDetail plan_id={props.plan_id} plan_term = {props.plan_term} plan_type= {props.plan_type} personalData={personalData} restData = {restaurantData}/>}
    </div>
  );
}

export default PurchasePlan;
