const data = [
    {
        id:1,
        question :`What is Printhook's and what does it offer?`,
        ans:`Printhook's is a comprehensive platform offering streamlined order management for seamless deliveries. It provides a centralized dashboard to effortlessly track and manage all orders, facilitating real-time updates, order tracking, performance metrics, and automatic receipts for enhanced expense management`   
    },
    {
        id:2,
        question :`How does Printhook's simplify order management?`,
        ans:`Printhook's offers a centralized dashboard that acts as a hub for managing orders. Users can seamlessly track and monitor all their orders from various sources in one place, making order management efficient and hassle-free.`    
    },
    {
        id:3,
        question :`Can I track order progress in real-time using Printhook's?`,
        ans:`Absolutely! Printhook's offers real-time updates and order tracking features, ensuring users have instant access to the status and progress of their orders.`    
    },
    {
        id:4,
        question :`How can Printhook's streamline my delivery processes?`,
        ans:`Printhook's offers a unified dashboard to oversee all reporting and insights in one place, allowing users to optimize their business operations for efficient delivery processes.`    
    },
    {
        id:5,
        question :`Can I access Printhook's dashboard from different devices?`,
        ans:`Yes, Printhook's centralized dashboard is accessible from various devices, providing flexibility for users to manage orders anytime, anywhere`    
    },
    {
        id:6,
        question :`Can I integrate Printhook's with various delivery partners ?`,
        ans:`Yes, Printhook's aggregates order information from different delivery partners, providing a comprehensive view and simplifying order handling.`    
    },
    {
        id:7,
        question :`Can I receive notifications for order updates with Printhook's ?`,
        ans:`Yes, Printhook's offers notification features, providing timely updates on order status and ensuring you stay informed throughout the process.`   
    },
    {
        id:8,
        question :`Is there a trial period or demo available for Printhook's?`,
        ans:`Yes, Printhook's offers a trial period and demos, allowing users to explore the platform and its features before making a commitment.`    
    },

]
export default data;