import React, { useState } from 'react';
import './Footer.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateChoice } from '../../redux/navbarSlice';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
function Footer(props) {
  const [year, setYear] = useState(new Date().getFullYear());
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const handleFooterLinks = (choice) => {
    if (props.redirectFooter) {
      navigation('/');
      dispatch(updateChoice(choice));
    }
  };
  const clickHelpandSupport = () => {
    const domain = process.env.REACT_APP_SUPPOR_URL;
    let a = document.createElement('a');
    a.href = domain;
    a.click();
  };
  return (
    <div>
      <div className='footer'>
        <div className='footer_first_content'>
          <div className='footer_description'>
            <h2>Printhooks Inc.</h2>
            <p>
              Looking for a restaurant in a specific state or city? Find
              thousands of restaurants near your location in the US by state and
              city.
            </p>
          </div>
          <div className='find_on_us'>
            <h2>Find us on</h2>
            <div className='find_icons'>
              <p>
                <a href='https://www.facebook.com/people/Printhooks-Inc/61557987043315/'>
                  <FacebookIcon sx={{ color: 'white', fontSize: 30 }} />
                </a>
              </p>
              <p>
                <InstagramIcon sx={{ color: 'white', fontSize: 30 }} />
              </p>
            </div>
          </div>
          <div className='footer_support'>
            <div>
              <h2>Support</h2>
              <div className='support_content'>
                <span>
                  <LocationOnIcon sx={{ color: '#818CF8' }} />
                </span>
                <p>173 Cambridge St #202 Burlington, MA 01803</p>
              </div>
              <div className='support_content'>
                <span>
                  <LocalPhoneIcon sx={{ color: '#818CF8' }} />
                </span>
                <p>
                  <span>
                    <a href='tel:+1 (781) 281-8363' rel='noopener noreferrer'>
                      888 881 2808
                    </a>
                  </span>
                </p>
              </div>
              <div className='support_content'>
                <span>
                  <EmailIcon sx={{ color: '#818CF8' }} />
                </span>
                <p>
                  <a href='mailto:info@printhooks.com'>info@printhooks.com</a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='footer_link_content'>
          <div className='footer_usefull_link'>
            <div style={{ width: '100%' }}>
              <h2
                className='usefull_header'
                style={{ color: '#818cf8', marginBottom: '10px' }}
              >
                Useful Links
              </h2>
              <div className='footer_usefull_link_grid'>
                <p>
                  {props.redirectFooter ? (
                    <>
                      <p
                        onClick={() => {
                          handleFooterLinks('faq_stop_scroll');
                        }}
                      >
                        FAQs
                      </p>
                    </>
                  ) : (
                    <ScrollLink to={'faq_stop_scroll'}>FAQs</ScrollLink>
                  )}
                </p>
                <p>
                  {props.redirectFooter ? (
                    <>
                      <p
                        onClick={() => {
                          handleFooterLinks('about_stop_scroll');
                        }}
                      >
                        Who we are?
                      </p>
                    </>
                  ) : (
                    <ScrollLink
                      to={'about_stop_scroll'}
                      onClick={handleFooterLinks}
                    >
                      Who we are?
                    </ScrollLink>
                  )}
                </p>
                <p>
                  {props.redirectFooter ? (
                    <>
                      <p
                        onClick={() => {
                          handleFooterLinks('pricing_stop_scroll');
                        }}
                      >
                        Pricing
                      </p>
                    </>
                  ) : (
                    <ScrollLink
                      to={'pricing_stop_scroll'}
                      onClick={handleFooterLinks}
                    >
                      Pricing
                    </ScrollLink>
                  )}
                </p>
                <p>
                  {props.redirectFooter ? (
                    <>
                      <p
                        onClick={() => {
                          handleFooterLinks('blog_stop_scroll');
                        }}
                      >
                        Blog
                      </p>
                    </>
                  ) : (
                    <ScrollLink
                      to={'blog_stop_scroll'}
                      onClick={handleFooterLinks}
                    >
                      Blog
                    </ScrollLink>
                  )}
                </p>
                <p>
                  <Link to='/3rd-party-order-intigrations-with-pos'>Third Party Order Intrgration with POS</Link>
                </p>
                <p>
                  <Link to='/product-featurs'>Product Features</Link>
                </p>
                <p>
                  <Link to='/pos-integration-page'>POS Integration</Link>
                </p>
                <p>
                  <Link to='/privacy'>Privacy policy</Link>
                </p>
                <p>
                  <Link to='/order-management-platform'>Order Management</Link>
                </p>
                <p>
                  <Link to='/centralized-order-tracking'>
                    Centralized Order Tracking
                  </Link>
                </p>
                <p>
                  <Link to='/food-delivery-aggregator'>
                    Food Delivery Aggregator
                  </Link>
                </p>
                <p>
                  <Link to='/restaurant-delivery-management'>
                    Restaurant Delivery Management
                  </Link>
                </p>
                <p>
                  <Link to='/multi-channel-order-monitoring'>
                    Multi Channel Order Monitoring
                  </Link>
                </p>
                <p>
                  <Link to='/franchise-order-handling'>
                    Franchise Order Handling
                  </Link>
                </p>
                <p>
                  <Link to='/streamlined-delivery-operations'>
                    Streamlined Delivery Operations
                  </Link>
                </p>
                <p>
                  <Link to='/efficient-order-management'>
                    Efficient Order Management
                  </Link>
                </p>
                <p>
                  <Link to='/integrated-delivery-solutions'>
                    Integrated Delivery Solutions
                  </Link>
                </p>
                <p>
                  <Link to='/real-time-order-updates'>
                    Real Time Order Updates
                  </Link>
                </p>
                <p>
                  <Link to='/menu-optimization-tools'>
                    Menu Optimization Tools
                  </Link>
                </p>
                <p>
                  <Link to='/automated-order-fulfillment'>
                    Automated Order Fulfillment
                  </Link>
                </p>
                <p>
                  <Link to='/seamless-delivery-coordination'>
                    Seamless Delivery Coordination
                  </Link>
                </p>
                <p>
                  <Link to='/customizable-order-workflows'>
                    Customizable Order workflows
                  </Link>
                </p>
                <p>
                  <Link to='/customer-support-services'>
                    Customer Support Services
                  </Link>
                </p>

                <p>
                  <Link to='/terms'>Terms and conditions</Link>
                </p>
                <p onClick={clickHelpandSupport}>Help & Support</p>
              </div>
            </div>
          </div>
        </div>
        <div className='footer_second_content'>
          <p> {`Copyright © ${year} - Printhook’s - All rights reserved.`}</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
