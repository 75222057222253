import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import axios from 'axios';

function Address({ setaddress, setAddressError, setRestData, address }) {
  const [places, setPlaces] = useState([]);
  const [query, setQuery] = useState('');

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const handleChange = async (event) => {
    setQuery(event.target.value);
    const url = 'https://places.googleapis.com/v1/places:autocomplete';
    const data = {
      input: event.target.value,
    };

    const headers = {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': GOOGLE_MAPS_API_KEY,
    };

    try {
      const response = await axios.post(url, data, { headers });
      console.log(response.data.suggestions);
      if (
        response.data.suggestions === undefined ||
        response.data.suggestions.length === 0 ||
        response.data.suggestions === null
      ) {
        setPlaces([]);
      } else {
        setPlaces(response.data.suggestions);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setPlaces([]);
    }
  };

  const getAddressDetails = async (value) => {
    console.log(value);
    const place_id = value?.placePrediction?.placeId;
    let city;
    let state;
    let country;
    let postal_code;

    const headers = {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': GOOGLE_MAPS_API_KEY,
      'X-Goog-FieldMask': 'id,displayName,addressComponents,adrFormatAddress',
    };

    const result = await fetch(
      `https://places.googleapis.com/v1/places/${place_id}`,
      { headers }
    );
    const data = await result.json();

    data?.addressComponents?.forEach((item) => {
      if (item.types.includes('country')) {
        country = item.longText;
      } else if (item.types.includes('postal_code')) {
        postal_code = item.longText;
      } else if (
        item.types.includes('locality') ||
        item.types.includes('sublocality')
      ) {
        city = item.longText;
      } else if (item.types.includes('administrative_area_level_1')) {
        state = item.longText;
      }
    });

    //SET CITY , STATE , COUNTRY AND POSTAL CODE
    setRestData((prevRestData) => ({
      ...prevRestData,
      place_id: place_id,
      city: city === undefined ? null : city,
      state: state === undefined ? null : state,
      postal_code: postal_code === undefined ? null : postal_code,
      country: country === undefined ? null : country,
    }));
  };

  const handleInputChange = (e, value) => {
    setaddress(value);
    if (value === '') {
      setPlaces([]);
      setAddressError('Address must be required');
    } else {
      setAddressError('');
    }
  };
  return (
    <>
      <Autocomplete
        id='address'
        freeSolo
        sx={{ width: 300 }}
        options={places}
        autoHighlight
        noOptionsText='No locations'
        inputValue={address}
        filterOptions={(x) => x}
        onInputChange={(event, newInputValue) => {
          handleInputChange(event, newInputValue);
        }}
        onChange={(event, newValue) => {
          getAddressDetails(newValue);
        }}
        getOptionLabel={(option) => {
          if (option?.placePrediction?.structuredFormat?.secondaryText?.text) {
            return `${option.placePrediction.structuredFormat.mainText.text}, ${option.placePrediction.structuredFormat.secondaryText.text}`;
          } else if (option?.placePrediction?.structuredFormat?.mainText.text) {
            return option.placePrediction.structuredFormat.mainText.text;
          } else {
            return '';
          }
        }}
        renderOption={(props, option) => {
          if (option.placePrediction.structuredFormat.secondaryText?.text) {
            var secondaryText =
              option.placePrediction.structuredFormat.secondaryText.text;
          } else {
            var secondaryText = null;
          }
          return (
            <li {...props}>
              <Grid container alignItems='center'>
                <Grid item sx={{ display: 'flex', width: 44 }}>
                  <LocationOnIcon sx={{ color: 'text.secondary' }} />
                </Grid>
                <Grid
                  item
                  sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
                >
                  <Box component='span'>
                    {option.placePrediction.structuredFormat.mainText.text}
                  </Box>
                  <Typography variant='body2' color='text.secondary'>
                    {secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            className='address-component'
            placeholder='Restaurant Address'
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
            name='restaurant_address'
            variant='standard'
            onChange={handleChange}
          />
        )}
      />
    </>
  );
}

export default Address;
