import React, { useEffect } from 'react'
import AfterPayment from '../../components/after_payment/AfterPayment'
import { useNavigate } from 'react-router-dom'

function Afterconfirm() {
    const navigation = useNavigate();
    useEffect(() => {
        setTimeout(() => {
            navigation('/');
        }, 5000);
    }, [])

    const textContent = (
        <>
            "Your request has been sent successfully. Management will contact you soon."
        </>
    );

    return (
       <>
       <AfterPayment text={textContent} page="request sent"/>
       </>
    )
}

export default Afterconfirm