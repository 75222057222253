import React, { useEffect, useState } from "react";
import Loading from "../../components/loading/Loading";
import ResourceLayout from "../../layout/ResourceLayout";
import blogsService from "../blog/Blog_service";
import { useNavigate } from "react-router-dom";

function Blog_detail() {
  const [loading, setLoading] = useState(true);
  const [blogdetail, setblogdetail] = useState({});
  const nevigate = useNavigate();
  useEffect(() => {
    blogsService
      .getdetailsblog(
        window.location.pathname.split("/")[
          window.location.pathname.split("/").length - 1
        ]
      )
      .then((data) => {
        if (data.title == "") {
          nevigate("/404");
        } else {
          setblogdetail(data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Rejected:", error);
        setLoading(false);
      });
  }, []);

  const description = blogdetail.description || "Description not available.";
  const paragraphs = description
    .split("\n")
    .map((paragraph, index) => <p key={index}>{paragraph}</p>);

  if (loading) {
    return <Loading fullHeight={true} />;
  }

  return (
    <>
      <ResourceLayout show_navbar={true}>
        <div className="blog_detail_page">
          <div
            className="blog_detail_title_and_back_img"
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${blogdetail.cover_photo})`,
            }}
          >
            <h1>{blogdetail.title}</h1>
          </div>
          <div className="blog_details">
            {paragraphs.length > 0 ? (
              paragraphs.slice(0, 2)
            ) : (
              <p>{description}</p>
            )}
            <div className="blog_detail_img">
              {blogdetail.attachment && (
                <img src={blogdetail.attachment} alt="Blog Attachment" />
              )}
            </div>
            {paragraphs.length > 2 ? paragraphs.slice(2) : null}
          </div>
        </div>
      </ResourceLayout>
    </>
  );
}

export default Blog_detail;
