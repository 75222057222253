import React, { useEffect, useState } from 'react';
import './Product_featurs.css';
import Loading from '../../components/loading/Loading';
import Single_page from '../../components/single_page/Single_page';

function Product_featurs() {
  const peras = [
    `All Third-party online orders by Printhook's offer a convenient and efficient way for Stores to acquire top-quality printing & peace of Mind solution without the need for multiple tablets & printers. These Third-party orders typically involve purchasing printers & tablets from all marketplaces. Printhook's Solution presents several advantages and considerations for Restaurants.`,
    `One key benefit of opting Printhook's is cost savings & seamless Inhouse operation. Many other companies offer competitive prices and discounts, but the technology & servers we use, the direct Integrations to 3rd parties & Pos association makes us top of the line in Quality.`,
    ` In conclusion, Printhook's for Restaurants offer a valuable alternative service for those seeking cost-effective & smooth operation, a diverse plan selection, and convenient signup experiences.`,
  ];
  return (
    <>
      <Single_page
        image='/assets/img/product_features_image.svg'
        heading='Product Features'
        img1='/assets/img/features.jpg'
        peras={peras}
        navbar={true}
      />
    </>
  );
}

export default Product_featurs;
