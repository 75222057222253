import React, { useEffect, useState } from 'react';
import './FAQ.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import data from '../../Faqdata';
import AOS from 'aos';
import 'aos/dist/aos.css';
function FAQ() {
    const [openItem, setOpenItem] = useState(null);
    useEffect(() => {
        AOS.init();
      }, [])
    const toggleItem = (index) => {
        if (openItem === index) {
            setOpenItem(null);
        } else {
            setOpenItem(index);
        }
    };

    return (
        <div className='faq_page'>
            <h1 data-aos="fade-up" data-aos-duration="500">FAQs</h1>
            <div className='faq_question_and_ans' data-aos="fade-up" data-aos-duration="500">
                {data.map((item, index) => (
                    <div className='question_and_ans' key={index} >
                        <div className='question_and_plusicon' onClick={() => toggleItem(index)}>
                            <h3>{item.question}</h3>
                            <div className='plus_icon'>
                                {openItem === index ?
                                    <IconButton onClick={() => toggleItem(index)}>
                                        <RemoveIcon sx={{ color: 'rgba(105, 111, 221, 1)' }} />
                                    </IconButton>
                                    :
                                    <IconButton onClick={() => toggleItem(index)}>
                                        <AddIcon sx={{ color: 'rgba(105, 111, 221, 1)' }} />
                                    </IconButton>
                                }
                            </div>
                        </div>
                        <Collapse in={openItem === index} timeout="auto" unmountOnExit>
                            <div className='ans'>
                                {item.ans}
                            </div>
                        </Collapse>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQ;
