import React, { useEffect, useRef, useState } from 'react';
import UserInfo from '../../components/userinfo/UserInfo';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addcustomPlan } from '../../redux/planSlice';
import { useDispatch } from 'react-redux';
import Address from '../../components/address/Address';
import LoadingButton1 from '../../components/button/LoadingButton1';
import ReCAPTCHA from 'react-google-recaptcha';
import { setReviewPageFalse } from '../../redux/otpSlice';
import { mobileSpamPatterns } from '../../components/common/common';

function CustomPlan(props) {
  const recaptchaRef = useRef();
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [customData, setCustomData] = useState({
    email: '',
    phone_number: '',
    restaurant_name: '',
    number_of_outlets: '',
    number_of_integrations: '',
    is_custom: true,
    source: 'Signup',
  });
  const [errors, setErrors] = useState({
    email: '',
    phone_number: '',
    restaurant_name: '',
    number_of_outlets: '',
    number_of_integrations: '',
  });
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const dispatch = useDispatch();
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    dispatch(setReviewPageFalse());
  }, []);

  const handleSubmit = (event) => {
    setButtonLoading(true);
    event.preventDefault();

    if (!recaptchaToken) {
      toast.error('Please complete the reCAPTCHA', {
        position: 'top-right',
        autoClose: 1000,
        closeButton: false,
      });
      setButtonLoading(false);
      return;
    }

    const finalCustomData = {
      ...customData,
      restaurant_address: address,
      recaptchaToken,
    };
    recaptchaRef.current.reset();
    setRecaptchaToken(null);
    dispatch(addcustomPlan(finalCustomData))
    .unwrap()
    .then((response) => {
      if (response.status === 201) {
        toast.success("Your request has been sent successfully. Management will contact you soon.", {
          position: 'top-right',
          autoClose: 1000,
          closeButton: false,
        });
        setButtonLoading(false);
        setTimeout(() => {
          props.setOpen(false);
        }, 2000);
      }
    })
    .catch((error) => {
      setButtonLoading(false);
      if (error.status === 400) {
        toast.error('The email already exists', {
          position: 'top-right',
            autoClose: 1000,
            closeButton: false,
          });
        }
      });
    };
    
    const handleOnChange = (event) => {
      const fieldName = event.target.name;
      let fieldValue = event.target.value;
      
      // Name Validation...
    if (fieldName === 'restaurant_name' && fieldValue.length < 5) {
      setErrors({
        ...errors,
        restaurant_name: 'Name must be at least 5 characters long.',
      });
    }

    // Phone number validation
    else if (fieldName === 'phone_number') {
      fieldValue = fieldValue.replace(/\D/g, '');
      const phoneRegex = /^\d{10}$/;
      if (fieldValue.length > 10) {
        fieldValue = fieldValue.slice(0, 10);
      } else if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          phone_number: 'Phone number is required',
        });
      } else if (!phoneRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          phone_number: 'Phone Number must have maximum 10 digits',
        });
      }else if (mobileSpamPatterns.includes(fieldValue.toLowerCase())) {
        setErrors({
          ...errors,
          phone_number: 'Please enter a valid phone number',
        });
      } 
      else {
        setErrors({
          ...errors,
          phone_number: '',
        });
      }
    }

    // Email Validation
    else if (fieldName === 'email') {
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          email: 'Email is required',
        });
      } else if (!emailRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          email: 'Email is not valid',
        });
      } else {
        setErrors({
          ...errors,
          email: '',
        });
      }
    }

    // Address Validation
    else if (fieldName === 'restaurant_address' && fieldValue.length < 5) {
      setErrors({
        ...errors,
        restaurant_address: 'Address must be at least 5 characters long.',
      });
    }

    // Outlet Validation
    else if (fieldName === 'number_of_outlets') {
      const numberRegex = /^[1-9][0-9]*$/;

      if (!numberRegex.test(fieldValue)) {
        fieldValue = '';
      } else if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          number_of_outlets: 'Number of store is required',
        });
      } else {
        setErrors({
          ...errors,
          number_of_outlets: '',
        });
      }
    }

    // Integration Validation
    else if (fieldName === 'number_of_integrations') {
      const numberRegex = /^[1-9][0-9]*$/;

      if (!numberRegex.test(fieldValue)) {
        fieldValue = '';
      } else if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          number_of_integrations: 'Number of third parties is required',
        });
      } else {
        setErrors({
          ...errors,
          number_of_integrations: '',
        });
      }
    }

    // No Error
    else {
      setErrors({
        ...errors,
        [fieldName]: '',
      });
    }
    setCustomData((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  };

  const isNextDisabled =
    errors.restaurant_name ||
    errors.phone_number ||
    errors.email ||
    errors.number_of_integrations ||
    errors.number_of_outlets ||
    customData.restaurant_name.trim() === '' ||
    customData.phone_number.trim() === '' ||
    customData.email.trim() === '' ||
    addressError ||
    customData.number_of_outlets.trim() === '' ||
    customData.number_of_integrations === '' ||
    !recaptchaToken;

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />

      <UserInfo
        imgUrl='/assets/img/CustomPlan.svg'
        onSubmit={handleSubmit}
        headingText='Custom Subscription Plan'
      >
        <div className='second_row'>
          <div className='row_input'>
            <input
              type='email'
              value={customData.email}
              placeholder='Email'
              id='email'
              name='email'
              onChange={handleOnChange}
            />
            <div className='error-message'>{errors.email}</div>
          </div>
          <div className='row_input'>
            <input
              type='text'
              value={customData.phone_number}
              placeholder='Phone'
              id='phone'
              name='phone_number'
              autoComplete='off'
              onChange={handleOnChange}
            />
            <div className='error-message'>{errors.phone_number}</div>
          </div>
        </div>
        <div className='third_row'>
          <input
            type='text'
            value={customData.restaurant_name}
            placeholder='Restaurant Name'
            id='restaurant_name'
            name='restaurant_name'
            onChange={handleOnChange}
          />
          <div className='error-message'>{errors.restaurant_name}</div>
        </div>
        <div className='third_row googel-location'>
          <Address setaddress={setAddress} setAddressError={setAddressError} />
          <div className='error-message'>{addressError}</div>
        </div>
        <div className='forth_row'>
          <div className='row_input'>
            <input
              type='text'
              value={customData.number_of_outlets}
              placeholder='Limit-free Store'
              pattern='\d+'
              id='no_outlet'
              name='number_of_outlets'
              onChange={handleOnChange}
            />
            <div className='error-message'>{errors.number_of_outlets}</div>
          </div>
          <div className='row_input'>
            <input
              type='text'
              value={customData.number_of_integrations}
              placeholder='Endless Third Parties'
              id='number_of_integrations'
              name='number_of_integrations'
              onChange={handleOnChange}
            />
            <div className='error-message'>{errors.number_of_integrations}</div>
          </div>
        </div>
        <div
          style={{
            marginTop: '10px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
            onChange={(token) => setRecaptchaToken(token)}
          />
        </div>
        <div className='next_button'>
          <LoadingButton1
            buttonloading={buttonLoading}
            isDisabled={isNextDisabled}
            text='Submit'
          />
        </div>
      </UserInfo>
    </>
  );
}

export default CustomPlan;
