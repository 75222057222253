import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';

function Centralized_order_tracking() {
  const peras = [
    `Running a successful restaurant demands impeccable order management. Centralized order tracking is the key to streamlining operations and ensuring customer satisfaction. With Printhook's, you gain access to a robust platform designed to simplify the complexities of order tracking.`,
    `Efficient order tracking is essential in the fast-paced restaurant industry. Printhook's offers a centralized solution that provides real-time updates on order status. From the moment an order is placed to its delivery, you can monitor progress effortlessly.`,
    `By centralizing your order tracking process, Printhook's eliminates the need to juggle multiple systems and platforms. All your order information is consolidated into one convenient location, allowing you to manage orders efficiently`,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Simplify order tracking with Printhook's. Real-time updates, streamlined operations, and enhanced customer satisfaction. Try now!"
        />
        <meta
          name='keywords'
          content='restaurant order tracking, centralized platform, real-time updates, streamlined operations, customer satisfaction'
        />
        <title>Printhook's: Centralized Order Tracking Platform</title>
      </Helmet>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading='Centralized Order Tracking Platform'
        img1='/assets/img/useimg.png'
        peras={peras}
        navbar={false}
      >
        <div className='order_managment'>
          <p>
            The real-time updates provided by Printhook's enable you to stay
            informed every step of the way. Whether you're managing a busy lunch
            rush or coordinating dinner deliveries, you can trust Printhook's to
            keep you in the loop.
          </p>

          <p>
            Efficiency is the cornerstone of success in the restaurant industry.
            Printhook's centralized order tracking platform allows you to
            optimize your operations, saving you time and effort. With
            everything you need in one place, you can focus on delivering
            exceptional service to your customers.
          </p>
          <p>
            Customer satisfaction is paramount in the competitive restaurant
            landscape. Printhook's streamlined order tracking ensures that every
            order is processed promptly and accurately. Real-time monitoring
            allows you to address any issues promptly, enhancing the customer
            experience.
          </p>
          <p>
            Experience the difference Printhook's can make in your restaurant's
            order tracking process. Sign up today and discover how our platform
            can streamline your operations, increase efficiency, and improve
            customer satisfaction. Simplify order tracking with Printhook's.
          </p>
        </div>
      </Single_page>
    </>
  );
}

export default Centralized_order_tracking;
