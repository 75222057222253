import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import otpService from "../pages/otp-verification/otp_services";

export const sendOtp = createAsyncThunk(
    'otp/sendOtp',
    async(email , {rejectWithValue}) =>{
        try{
         const response = await otpService.sendOtp(email);
         return response;
        }
        catch(error){
          return rejectWithValue(error.response);
        }
    }
)

export const verifiyEmail = createAsyncThunk(
    'otp/verifiyEmail',
    async(verifiedData , {rejectWithValue}) =>{
        try{
         const response = await otpService.verifiyEmail(verifiedData);
         return response;
        }
        catch(error){
          return rejectWithValue(error.response);
        }
    }
)
const otpSlice = createSlice(
    {
        name:'otp',
        initialState:{
            reviewPage: false
        },
        reducers:{
            setReviewPageTrue: (state) => {
                state.reviewPage = true;
              },
            setReviewPageFalse:(state) =>{
                state.reviewPage = false;
            }
        },
        extraReducers:{
            [sendOtp.fulfilled]:(state,action) => action.payload,
            [verifiyEmail.fulfilled]:(state,action) => action.payload,
        }
    }
)
export const { setReviewPageTrue , setReviewPageFalse } = otpSlice.actions;
export default otpSlice.reducer;