import React, { useEffect, useRef, useState } from 'react';
import UserInfo from '../../components/userinfo/UserInfo';
import { createRestaurant } from '../../redux/restaurantSlice';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Address from '../../components/address/Address';
import LoadingButton1 from '../../components/button/LoadingButton1';
import ReCAPTCHA from 'react-google-recaptcha';
import {
  alphabetArray,
  include_name,
  mobileSpamPatterns,
} from '../../components/common/common';
import { setReviewPageFalse } from '../../redux/otpSlice';

function RestaurantInfo(props) {
  const dispatch = useDispatch();
  const recaptchaRef = useRef();
  const [loading, setLoading] = useState(false);
  const [address, setaddress] = useState('');
  const [address_error, setAddressError] = useState('');
  const [restData, setRestData] = useState({
    restaurant_name: '',
    phone_number: '',
    email: '',
    source: 'Signup',
  });
  const [errors, setErrors] = useState({
    restaurant_name: '',
    phone_number: '',
    email: '',
  });
  const [buttonloading, setButtonloading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  useEffect(() => {
   dispatch(setReviewPageFalse())
  }, [])
  
  const handleInputChange = (event) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;
    
    // Name Validation...
    if (fieldName === 'restaurant_name' && fieldValue.length < 5) {
      setErrors({
        ...errors,
        restaurant_name: 'Name must be at least 5 characters long.',
      });
    }
    // Phone number validation
    else if (fieldName === 'phone_number') {
      fieldValue = fieldValue.replace(/\D/g, '');
  
      const phoneRegex = /^\d{10}$/;
      if (fieldValue.length > 10) {
        fieldValue = fieldValue.slice(0, 10);
      }
      
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          phone_number: 'Phone number is required',
        });
      } else if (!phoneRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          phone_number: 'Phone Number must have maximum 10 digits',
        });
      } else if (mobileSpamPatterns.includes(fieldValue.toLowerCase())) {
        setErrors({
          ...errors,
          phone_number: 'Please enter a valid phone number',
        });
      } else {
        setErrors({
          ...errors,
          phone_number: '',
        });
      }
  
      setRestData({
        ...restData,
        phone_number: fieldValue,
      });
    }
    // Email Validation
    else if (fieldName === 'email') {
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          email: 'Email is required',
        });
      } else if (!emailRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          email: 'Email is not valid',
        });
      } else {
        setErrors({
          ...errors,
          email: '',
        });
      }
    } else {
      setErrors({
        ...errors,
        [fieldName]: '',
      });
    }
    setRestData((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  };

  const handleSubmit = (event) => {
    setButtonloading(true);
    event.preventDefault();

    if (!recaptchaToken) {
      toast.error('Please complete the reCAPTCHA', {
        position: 'top-right',
        autoClose: 1000,
        closeButton: false,
      });
      setButtonloading(false);
      return;
    }

    // if (
    //   include_name.some((str) =>
    //     restData.restaurant_name.toLowerCase().includes(str)
    //   ) ||
    //   alphabetArray.some((str) =>
    //     restData.restaurant_name.toLowerCase().includes(str)
    //   )
    // ) {
    //   toast.error('Please Enter Valid restaurant name.', {
    //     position: 'top-right',
    //     autoClose: 1000,
    //     closeButton: false,
    //   });
    //   setButtonloading(false);
    //   return;
    // }

    // if (mobileSpamPatterns.includes(restData.phone_number.toLowerCase())) {
    //   toast.error('Please Enter Valid Mobile No.', {
    //     position: 'top-right',
    //     autoClose: 1000,
    //     closeButton: false,
    //   });
    //   setButtonloading(false);
    //   return;
    // }
    recaptchaRef.current.reset();
    setRecaptchaToken(null);
    const restaurantData = {
      ...restData,
      number_of_integrations: props.integration,
      number_of_outlets: props.outlet,
      plan: props.plan_id,
      restaurant_address: address,
      recaptchaToken,
    };
    dispatch(createRestaurant(restaurantData))
    .unwrap()
    .then((response) => {
      if (response.status === 201) {
        props.onNext(response.data);
        setButtonloading(false);
        recaptchaRef.current.reset();
        }
      })
      .catch((error) => {
        console.log(error);
        setButtonloading(false);
        if (error.status === 400) {
          toast.error('The email already exists', {
            position: 'top-right',
            autoClose: 1000,
            closeButton: false,
          });
        }
      });
  };

  const handleBackButtonClick = () => {
    props.onPrevious(props.personalData);
  };

  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const isNextDisabled =
    errors.restaurant_name ||
    errors.phone_number ||
    errors.email ||
    address_error ||
    restData.restaurant_name.trim() === '' ||
    restData.phone_number.trim() === '' ||
    restData.email.trim() === '' ||
    address.trim() === '' ||
    !recaptchaToken;

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <UserInfo
        imgUrl='/assets/img/RestaurantInformation.svg'
        onSubmit={handleSubmit}
        headingText='Restaurant Information'
        h1='restaurant'
      >
        <div className='info-form-group'>
          <input
            type='text'
            placeholder='Restaurant Name'
            id='rest_name'
            value={restData.restaurant_name}
            onChange={handleInputChange}
            name='restaurant_name'
          />
          <div className='error-message'>{errors.restaurant_name}</div>
        </div>
        <div className='info-form-group'>
          <input
            type='text'
            placeholder='Phone'
            id='phone'
            value={restData.phone_number}
            onChange={handleInputChange}
            name='phone_number'
            autoComplete='off'
          />
          <div className='error-message'>{errors.phone_number}</div>
        </div>
        <div className='info-form-group'>
          <input
            type='email'
            placeholder='Email'
            id='email'
            value={restData.email}
            onChange={handleInputChange}
            name='email'
          />
          <div className='error-message'>{errors.email}</div>
        </div>
        <div className='info-form-group googel-location'>
          <Address
            setaddress={setaddress}
            handleInputChange={handleInputChange}
            setAddressError={setAddressError}
            setRestData={setRestData}
            restData={restData}
            address={address}
          />
          <div className='error-message'>{address_error}</div>
        </div>
        <div className='info-form-group'>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
            onChange={onRecaptchaChange}
          />
        </div>
        <div className='back_next_restaurant'>
          <div>
            <LoadingButton1
              buttonloading={buttonloading}
              isDisabled={isNextDisabled}
              text='Next'
            />
          </div>
        </div>
      </UserInfo>
    </>
  );
}
export default RestaurantInfo;
