import React, { useEffect, useState } from 'react';
import './Blog_page.css';
import ResourceLayout from '../../layout/ResourceLayout';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Loading from '../../components/loading/Loading';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import blogsService from '../blog/Blog_service';
function Blog_page() {
  const [loading, setLoading] = useState(true);
  const [blogs, setblogs] = useState([]);
  const navigation = useNavigate();

  useEffect(() => {
    blogsService
      .listofblog()
      .then((data) => {
        setblogs(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Rejected:', error);
        setLoading(false);
      });
  }, []);

  const clickReadArtical = (blog) => {
    navigation(`/blog/${blog.slug}`);
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  if (loading) {
    return <Loading fullHeight={true} />;
  }

  return (
    <>
      <ResourceLayout option='Blog'>
        <div className='blog_page_content'>
          <div className='blog_page_slider'>
            <Swiper
              modules={[Autoplay, Navigation, Pagination]}
              pagination={{
                clickable: true,
              }}
              autoplay={{ delay: 4000, disableOnInteraction: false }}
              loop={true}
              className='my_slider'
            >
              {blogs.map((blog) => (
                <SwiperSlide>
                  <div
                    className='blog_back_img_and_content'
                    style={{
                      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${blog.cover_photo}')`,
                    }}
                  >
                    <div className='blog_page_content_heading'>
                      <h1>{blog.title}</h1>
                    </div>
                    {/* <div className='profile_and_name'>
                      <span>
                        <img src='/assets/img/profile.svg' />
                      </span>
                      <span>By {blog.username} in blog</span>
                    </div> */}
                    <div
                      className='read_artical_button'
                      onClick={() => clickReadArtical(blog)}
                    >
                      <button>
                        Read Artical
                        <div className='forward_arrow'>
                          <ArrowForwardIcon />
                        </div>
                      </button>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          <div className='blogs'>
            {blogs.map((blog) => (
              <Card
                sx={{ width: 400 }}
                className='cardContainer blogs_container'
                onClick={() => clickReadArtical(blog)}
              >
                <CardMedia
                  component='img'
                  height='240'
                  image={blog.cover_photo}
                  alt='Paella dish'
                  className='card-img'
                />
                <CardContent className='card_content'>
                  <Typography variant='p' className='card_heading'>
                    {truncateText(blog.title, 3)}
                  </Typography>
                  <Typography
                    sx={{ marginTop: '10px' }}
                    className='card_discription'
                  >
                    {truncateText(blog.description, 50)}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
      </ResourceLayout>
    </>
  );
}

export default Blog_page;
