import React, { useEffect } from 'react';
import './Blog.css';
import { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/less/pagination';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  CardActions,
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import blogsService from './Blog_service';
function Blog() {
  const [swiperRef, setSwiperRef] = useState(null);
  const [blogs, setblogs] = useState([]);
  const DOMAIN = process.env.REACT_APP_FRONT_FLOW_URL;

  useEffect(() => {
    blogsService
      .listofblog()
      .then((data) => {
        setblogs(data);
      })
      .catch((error) => {
        console.error('Rejected:', error);
      });
  }, []);
  const card_infos = [
    {
      img: '/assets/img/blog1.svg',
      heading: '5 Creative Restaurant Marking Ideas to Try Today',
      discrription:
        ' To stand out in this fierce culinary landscape, you need more than just a pinch of creativity in your marketing recipe. In this blog, we’ll serve up a delectable platter of inspiration with five innovative restaurant marketing ideas that you can start implementing today.',
      username: 'James Smith',
      date: 'November 10,2023',
    },
    {
      img: '/assets/img/blog3.svg',
      heading: '5 Creative Restaurant Marking Ideas to Try Today',
      discrription:
        ' To stand out in this fierce culinary landscape, you need more than just a pinch of creativity in your marketing recipe. In this blog, we’ll serve up a delectable platter of inspiration with five innovative restaurant marketing ideas that you can start implementing today.',
      username: 'James Smith',
      date: 'November 10,2023',
    },
  ];
  const handleBlogClick = (info) => {
    window.open(`${DOMAIN}blog/${info.slug}`, '_blank');
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };
  return (
    <>
      <div className='blog_page'>
        <h1>Blog</h1>
        <div className='blog_slider'>
          {blogs.length > 0 ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              breakpoints={{
                640: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1200: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1800: {
                  slidesPerView: 4,
                  spaceBetween: 30,
                },
              }}
              modules={[Pagination, Autoplay]}
              autoplay={{ delay: 2000 }}
              className='mySwiper'
            >
              {blogs.map((card_info) => (
                <SwiperSlide
                  className='my_card_swiper'
                  onClick={() => handleBlogClick(card_info)}
                >
                  <Card
                    sx={{
                      width: 400,
                      height: '530px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                    className='cardContainer'
                  >
                    <CardMedia
                      component='img'
                      height='240'
                      image={card_info.cover_photo}
                      alt='Paella dish'
                      className='card-img'
                      loading='lazy'
                    />
                    <CardContent className='card_content'>
                      <Typography variant='h6' className='card_heading'>
                        {truncateText(card_info.title, 3)}
                      </Typography>
                      <Typography
                        sx={{ marginTop: '10px' }}
                        className='card_discription'
                      >
                        {truncateText(card_info.description, 50)}
                      </Typography>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <h1>No Blog Found</h1>
          )}
        </div>
      </div>
      <div id='faq_stop_scroll'></div>
    </>
  );
}

export default Blog;
