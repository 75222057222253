import { createAsyncThunk , createSlice } from "@reduxjs/toolkit";
import planService from "../pages/purchase-plan/plan_service";

export const getAllPlan = createAsyncThunk(
    'plan/getAllPlan',
    async(_ , {rejectWithValue}) =>{
        try {
            const response = await planService.getAllPlan();
            return response;
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)

export const getPlanById = createAsyncThunk(
    'plan/getPlanById' , 
    async (id , {rejectWithValue}) =>{
        try {
            const response = await planService.getPlanByID(id);
            return response
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)

export const changePlan = createAsyncThunk(
    'plan/changePlan' , 
    async (planData , {rejectWithValue}) =>{
        try {
            const response = await planService.changePlan(planData);
            return response
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)
export const addcustomPlan = createAsyncThunk(
    'plan/addcustomPlan',
    async (custom_data , {rejectWithValue}) =>{
        try {
            const response = await planService.addcustomPlan(custom_data);
            return response
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)
export const payment = createAsyncThunk(
    'plan/payment' ,
    async (paymentData , {rejectWithValue}) =>{
        try {
            const response = await planService.payment(paymentData);
            return response
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)


export const paymentFailure = createAsyncThunk(
    'plan/paymentfailure' ,
    async(id , {rejectWithValue}) =>{
        try {
            const response = await planService.payment_failure(id);
            return response
        } catch (error) {
            return rejectWithValue(error.response)
        }
    }
)
const planSlice = createSlice(
    {
        name:'plan',
        initialState: {

        },
        reducers:{},
        extraReducers:{
            [getAllPlan.fulfilled]:(state , action) =>action.payload,
        }
    },
)


export default planSlice.reducer