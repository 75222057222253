import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import RestaurantConfig from "../pages/restaurantinfo/Restaurant_config";
import restaurantService from "../pages/restaurantinfo/Restaurant_service";
export const createRestaurant = createAsyncThunk(
    'restaurant/createRestaurant',
    async (restaurantData, { rejectWithValue }) => {
      try {

        const response = await restaurantService.createRestaurnt(restaurantData);
        return response;

      } catch (error) {

        return rejectWithValue(error.response);
      }
    }
  );

  export const confirmPlan = createAsyncThunk(
    'restaurant/confirmPlan',
    async (confirmData, { rejectWithValue }) => {
      try {

        const response = await restaurantService.confirmPaln(confirmData);
        return response;

      } catch (error) {

        return rejectWithValue(error.response);
      }
    }
  );

  export const fetchRestById = createAsyncThunk(
    'restaurant/fetchRestById',
    async(rest_id , {rejectWithValue}) =>{
      try {
        const response = await restaurantService.fetchRestByID(rest_id);
        return response
      } catch (error) {
        return rejectWithValue(error.response)
      }
    }
  )

  export const createContact = createAsyncThunk(
    'restaurant/createContact',
    async (contact_data, { rejectWithValue }) => {
      try {

        const response = await restaurantService.contact_details(contact_data);
        return response;

      } catch (error) {

        return rejectWithValue(error.response);
      }
    }
  );

const restaurantSlice = createSlice(
    {
        name:'restaurant',
        initialState:{
          preventScroll:false
        },
        reducers:{
          setpreventScroll: (state, action) => {
            state.preventScroll = action.payload;
          },
        },
        extraReducers:{
            [createRestaurant.fulfilled]:(state,action) => action.payload,
        }
    }
)
export const { setpreventScroll } = restaurantSlice.actions;


export default restaurantSlice.reducer;