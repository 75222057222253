import React from 'react'
import { LoadingButton } from '@mui/lab';
import { CircularProgress } from '@mui/material';

function LoadingButton1({buttonloading , isDisabled , text , onClick , sx ,size}) {
    return (
        <LoadingButton
            variant='contained'
            className=' w-full mt-16'
            loading={buttonloading}
            aria-label='Sign in'
            disabled={isDisabled}
            type='submit'
            size={size}
            onClick={onClick}
            loadingIndicator={<CircularProgress color='inherit' size={22} />}
            sx={{
                fontSize: '1.2rem',
                fontWeight: 600,
                backgroundColor: '#818CF8',
                textTransform: 'none',
                paddingX: '35px',
                paddingY: '5px',
                borderRadius: '10px',
                '&:hover': {
                    backgroundColor: '#717EF8',
                },
                '&.MuiLoadingButton-loading': {
                    backgroundColor: '#818CF8',
                },
                '& .MuiLoadingButton-loadingIndicator': {
                    color: (theme) => theme.palette.common.white,
                },
                ...sx
            }}
        >
            {text}
        </LoadingButton>
    )
}

export default LoadingButton1