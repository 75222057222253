import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';

function Order_managment_page() {
  const peras = [
    `Are you seeking to streamline your restaurant's operations for improved efficiency and customer satisfaction? Look no further than Printhook's. Our comprehensive platform offers a centralized solution to manage all aspects of your restaurant's workflow.`,
    `With Printhook's, you can efficiently handle order aggregation, track deliveries in real-time, and optimize your menu offerings for maximum profitability. Our platform simplifies the complexities of restaurant management, allowing you to focus on delivering exceptional service to your customers.`,
    `Gone are the days of juggling multiple systems and struggling to keep up with orders. Printhook's intuitive interface makes it easy to manage orders from various sources, ensuring smooth operations and timely delivery.`,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Streamline restaurant operations with Printhook's centralized platform. Manage orders, track deliveries, and optimize menus for efficiency"
        />
        <meta
          name='keywords'
          content="Printhook's, restaurant operations, order management, centralized platform, track deliveries, optimize menus, productivity, customer satisfaction"
        />
        <title>Printhook's: Restaurant Order Management Platform</title>
      </Helmet>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading='Restaurant Order Management Platform'
        img1='/assets/img/useimg.png'
        peras={peras}
        navbar={false}
      >
        <div className='order_managment'>
          <p>
            Furthermore, our platform provides powerful tools for menu
            optimization, allowing you to analyze sales data and adjust your
            menu offerings accordingly. By identifying popular items and
            eliminating underperforming ones, you can maximize your revenue and
            enhance customer satisfaction.
          </p>

          <p>
            Printhook's also offers integrated delivery solutions, allowing you
            to coordinate with delivery partners effortlessly. Whether you
            operate a single restaurant or a chain of franchises, our platform
            can adapt to your needs and streamline your delivery processes.
          </p>
          <p>
            At Printhook's, we are committed to helping restaurants succeed. Our
            platform is designed to enhance productivity, improve customer
            satisfaction, and drive growth for your business. Experience the
            difference with Printhook's today.
          </p>

          {/* <h3>Changes to Policy</h3>
          <p>
            {' '}
            We reserve the right to modify this Policy at any time, so please
            review Printhook's frequently for any changes. Amendments to this
            Policy will be posted on the Site and will be effective when posted.
            You will be deemed to have been made aware of, will be subject to,
            and will be deemed to have accepted, the changes to any amended or
            revised policy by your continued access or use of our services or
            Printhook's.
          </p>

          <h3>Your Consent </h3>
          <p>
            Please read this entire policy carefully before using Printhook's or
            providing us with any information. When you access or use
            Printhook's, you consent to the collection, use and disclosure of
            your information in accordance with Terms and Conditions of Use and
            with this Policy. By providing information to Printhook's, all users
            fully understand and consent to the collection, use and processing
            of such information by Printhook's or third parties as set forth
            herein. By using Printhook's, all Users consent to us providing all
            information (whether or not personally identifiable) received or
            collected from those Users to such third-party providers for the
            purpose of enabling the third-party provider to provide these
            outsourced services to us. In addition, all Users consent to the
            collection, maintenance, and processing of their information
            (whether or not personally identifiable) by us and these third-party
            providers. If you do not agree to the Terms and Conditions of Use
            and the terms of this Policy, you are not authorized to use the
            Printhook's website.
          </p>

          <p>
            Please understand that we may contract with third parties to provide
            various services to Printhook's relating to Printhook's on an
            outsourced basis rather than performing those services ourselves.
            For example, we may (i) contract with an email provider to handle
            the distribution of email messages to Users, (ii) contract with a
            web site hosting provider to host Printhook's for us or (iii)
            contract with a third party to provide call center services to
            facilitate communication in connection with our services. We may
            also contract with various validation services to confirm contact
            information you provide.
          </p>

          <p>
            Maintaining your trust is our top priority, so we adhere to the
            following principles to protect your privacy:
          </p>

          <ul>
            <li>
              We protect your personally identifiable information and will only
              provide it to third parties: (1) with your consent; (2) where it
              is necessary to carry out your instructions; (3) as reasonably
              necessary in order to provide our features and functionality to
              you; (4) when we reasonably believe it is required by law,
              subpoena or other legal process; or (5) as necessary to enforce
              our Terms of Use or protect the rights, property, or safety of us,
              our Members and Site visitors, and the public. We have implemented
              appropriate security safeguards designed to protect your
              information in accordance with industry standards.
            </li>
            <li>
              Our Site is not directed to children under the age of 13. We do
              not knowingly collect personal information from children under age
              13. Printhook's complies with the Children&rsquo;s Online Privacy
              Protection Act (COPPA). We do not knowingly collect or use
              personally identifiable information from children under the age of
              13. If we discover such information from a child under the age of
              13, we will eliminate that data.
            </li>
          </ul>

          <h3>Information You Give Us</h3>
          <p>
            We may collect and retain any information from you or your business
            provided to us when you visit a Site, including when you:
          </p> */}
        </div>
      </Single_page>
    </>
  );
}

export default Order_managment_page;
