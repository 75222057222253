import axios from "axios";
import OtpConfig from "./otp_config";

class OtpService {
    sendOtp(email) {
        return new Promise((resolve, reject) => {
          axios
            .post(OtpConfig.sendOtp , email)
            .then((response) => {
              if (response) {
                resolve(response);
              } else {
                reject(response.data.error);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }

      verifiyEmail(verifiedData) {
        return new Promise((resolve, reject) => {
          axios
            .post(OtpConfig.verifiyEmail , verifiedData)
            .then((response) => {
              if (response) {
                resolve(response);
              } else {
                reject(response.data.error);
              }
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
}

const otpService = new OtpService();

export default otpService;