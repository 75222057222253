import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';

function Franchise_order_handling() {
  const peras = [
    `Printhook's revolutionizes franchise order handling with its centralized platform designed to streamline operations and ensure consistency across all franchise locations. Managing orders for multiple franchise outlets can be complex, but with Printhook's, it becomes effortless.`,
    `Our platform provides a centralized hub for franchise order management, allowing franchise owners to monitor and track orders from all their locations in one place. Whether you have two franchise outlets or twenty, Printhook's ensures consistency in order processing and fulfillment.`,
    `
    Franchise owners can customize order workflows to fit the specific needs of their business while maintaining brand standards across all locations. With Printhook's, you can automate order processing, reduce errors, and improve efficiency in franchise operations.
    `,
    `
    Real-time order updates and notifications keep franchise owners informed about order status changes, ensuring timely fulfillment and customer satisfaction. Printhook's intuitive dashboard provides insights into order performance, allowing franchise owners to make data-driven decisions to optimize their operations.
    `,
    `
    By centralizing order handling for franchise locations, Printhook's simplifies the complexities of franchise management and empowers owners to focus on growing their business. Experience the benefits of streamlined franchise order handling with Printhook's today.
    `,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Simplify franchise order management with Printhook's. Our centralized platform ensures consistency across all franchise locations, optimizing order processing and improving efficiency."
        />
        <meta
          name='keywords'
          content='franchise order handling, franchise operations, order management, centralized platform'
        />
        <title>Streamlined Franchise Order Handling with Printhook's</title>
      </Helmet>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading="Streamlined Franchise Order Handling with Printhook's"
        img1='/assets/img/useimg.png'
        peras={peras}
        navbar={false}
      ></Single_page>
    </>
  );
}

export default Franchise_order_handling;
