import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';

function Multi_channel_order_monitoring() {
  const peras = [
    `Printhook's offers a powerful solution for restaurants to monitor orders from multiple channels effortlessly. With our multi-channel order monitoring feature, you can track, manage, and optimize your restaurant's order flow for maximum efficiency.`,
    `In today's digital age, customers place orders through various channels, including online platforms, phone orders, and in-person visits. Keeping track of orders from these different channels can be challenging, but Printhook's simplifies the process.`,
    `
    Our platform aggregates all your order information in one centralized location, making it easy to monitor orders from any source. Whether it's orders placed through your website, third-party delivery apps, or over the phone, Printhook's provides a comprehensive view of your order flow.
    `,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Monitor orders from multiple channels seamlessly with Printhook's. Track, manage, and optimize your restaurant order flow for maximum efficiency."
        />
        <meta
          name='keywords'
          content='multi-channel order monitoring, order tracking, order management, restaurant operations'
        />
        <title>Multi-Channel Order Monitoring with Printhook's</title>
      </Helmet>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading="Multi-Channel Order Monitoring with Printhook's"
        img1='/assets/img/useimg.png'
        peras={peras}
        navbar={false}
      >
        <div className='order_managment'>
          <p>
            With Printhook's intuitive dashboard, you can track orders in
            real-time, monitor order status changes, and ensure timely
            fulfillment. Say goodbye to manual order tracking and streamline
            your restaurant operations with Printhook's.
          </p>

          <p>
            Managing orders from multiple channels requires efficient order
            management processes. Printhook's automates order processing,
            reduces errors, and improves order accuracy. Our platform allows you
            to customize order workflows to fit your specific business needs,
            ensuring smooth order management every step of the way.
          </p>
          <p>
            Printhook's empowers you to optimize your restaurant operations and
            deliver exceptional customer experiences. With multi-channel order
            monitoring, you can stay ahead of the curve and meet the demands of
            today's dynamic restaurant industry.
          </p>
          <p>
            Try Printhook's today and take control of your order management
            process like never before.
          </p>
        </div>
      </Single_page>
    </>
  );
}

export default Multi_channel_order_monitoring;
