import React, { useEffect } from 'react'
import ResourceLayout from '../../layout/ResourceLayout'
import ContactFrom from './ContactForm'
import './Contact.css'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
function Contact() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  const clickHelpandSupport = ()=>{
    const domain = process.env.REACT_APP_SUPPOR_URL;
    let a = document.createElement('a');
    a.href = domain;
    a.click();
}

  return (
    <>
    <ResourceLayout show_navbar={true} option = 'Contact'>

      <Box className = 'contact_page'>
       <Box className='contact_first_block'>
       <Typography variant = 'h3' sx={{fontWeight:600 , textAlign:'center' , marginTop:20}}>
      Can streamlined online ordering boost profits?
        </Typography>
        <Typography variant = 'h6' sx={{fontWeight:500 , marginTop:3, textAlign:'center'}}>
        Let's explore how we can enhance your revenue with smoother processes. Ask us how today!
        </Typography>
       </Box>
      <ContactFrom/>
      </Box>
      <Box className='contact_second_block'>
       <Typography  variant = 'h4' sx={{fontWeight:600 , textAlign:'center'}}>
       Require any assistance? We're ready to assist you.
       </Typography>
       <Typography variant = 'h6' sx={{fontWeight:500 , marginTop:3, textAlign:'center'}}>
       Seeking information or considering a temporary service pause?   
        </Typography>
        <Typography variant = 'h6' sx={{fontWeight:500 , marginTop:3, textAlign:'center'}}>
        Explore our <span onClick={clickHelpandSupport}>help center</span> for any assistance you may need. Our restaurant support team is dedicated to helping you further.
        </Typography>
        <Typography variant = 'h6' sx={{fontWeight:500 , marginTop:3, textAlign:'center'}}>
        We're available 24/7, contact us at <span>888 881 2808</span>
        </Typography>
      </Box>
    </ResourceLayout>
    </>
  )
}

export default Contact