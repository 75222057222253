import React, { useEffect, useState } from 'react'
// import './Pricing.css'
import Pricingcard from './Pricingcard';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { confirmPlan } from '../../redux/restaurantSlice';
import { ToastContainer, toast } from 'react-toastify';
import { getAllPlan } from '../../redux/planSlice';
import Loading from '../../components/loading/Loading';
function Pricing(props) {
    const [toggle, setToggle] = useState('monthly');
    const location = useLocation();
    const after_Otp_verification = location.state ? true : false
    const [monthly_plan , setMonthly_plan] = useState([]);
    const [anually_plan , setAnually_plan] = useState([])
    const [plan_loading , setPlan_loading] = useState(true)
    const dispatch = useDispatch();
 
    useEffect(() => {
        dispatch(getAllPlan())
          .unwrap()
          .then((response) => {
            const monthlyPlans = response.data.filter((plan) => plan.id === 1 || plan.id === 2 || plan.id === 3);
            const annuallyPlans = response.data.filter((plan) => plan.id === 4 || plan.id === 5 || plan.id === 6);
      
            const customPlan = {
              id: 'custom',
              title: 'CUSTOM',
              description: 'Custom Package',
              outlet: 'Limit-free Outlets   ',
              integration: 'Endless integration',
              price: 'Tailored pricing'
            };
    
           { !props.custom_not_show && monthlyPlans.push(customPlan)};
           { !props.custom_not_show && annuallyPlans.push(customPlan)};
      
            setMonthly_plan(monthlyPlans);
            setAnually_plan(annuallyPlans);
            setPlan_loading(false);
          });
          
      }, []);
      
    useEffect(() => {
     
                if (props.plan_id !==undefined) {
                       if (anually_plan.some(plan => plan.id === props.plan_id)) {
                handleToggleClick('annually');
            }
         
        }
    }, [anually_plan , monthly_plan]);

    const handleToggleClick = (selectedOption) => {
        setToggle(selectedOption);
    };

    const changed_palan_id = (id )=>{
        props.changed_palan_id(id)
    }
    if (props.plan_loading && plan_loading) {
        return <Loading/>
    }
    return (
        <>
                <div className='pricing-page' id='pricing_page'>
                <div className='toggle'>
                    <div
                        className={`toggle-left ${toggle === 'monthly' ? 'toggle_back_color' : ''}`}
                        onClick={() => handleToggleClick('monthly')}
                    >
                        Monthly
                    </div>
                    <div
                        className={`toggle-right ${toggle === 'annually' ? 'toggle_back_color' : ''}`}
                        onClick={() => handleToggleClick('annually')}
                    >
                        Annually
                    </div>
                </div>


                <div className='pricing-cards'>
                    <div className="cards" >
                        {toggle === 'monthly' ?
                            (
                                monthly_plan.map((plan, index) => (

                                    <Pricingcard

                                        key={plan.id}
                                        id={plan.id}
                                        title={plan.title}
                                        desc = {plan.description}
                                        outlet = {plan.max_outlets}
                                        integration = {plan.max_integrations}
                                        price = {plan.price}
                                        index={index}
                                        cards_array={monthly_plan}
                                        changed_palan_id = {changed_palan_id}
                                        selected = {props.selected}
                                        selected_id = {props.plan_id}
                                    />

                                ))

                            )
                            :
                            (

                                anually_plan.map((plan, index) => (


                                    <Pricingcard
                                        key={plan.title}
                                        id={plan.id}
                                        title={plan.title}
                                        desc = {plan.description}
                                        outlet = {plan.max_outlets}
                                        integration = {plan.max_integrations}
                                        price = {plan.price}
                                        index={index}
                                        cards_array={anually_plan}
                                        changed_palan_id = {changed_palan_id}
                                        selected = {props.selected}
                                        selected_id = {props.plan_id}
                                    />

                                ))
                            )
                        }

                    </div>
                </div>
            </div>
            <div id='about_stop_scroll'></div>
        </>

    );

}

export default Pricing
