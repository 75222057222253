import axios from "axios";
import PlanConfig from "./plan_config";

class PlanService {
   getAllPlan()
   {
     return new Promise((resolve , reject)=>{
        axios.get(PlanConfig.getAllPlan)
        .then((response)=>{
            if(response)
            {
                resolve(response);
            }
            else
            {
                reject(response.data.error)
            }
        })
        .catch((error)=>{
            reject(error)
        })
     })
   }

   getPlanByID(id)
   {
    return new Promise((resolve ,  reject)=>{
        axios.get(`${PlanConfig.getPlanById}${id}`)
        .then((response)=>{
            if (response) {
                resolve(response)
            } else {
                reject(response.data.error)
            }
        })
        .catch((error)=>{
            reject(error)
        })
    })
   }

   changePlan(planData)
   {
    return new Promise((resolve ,  reject)=>{
      axios.patch(`${PlanConfig.changePlan}`, planData)
      .then((response)=>{
          if (response) {
              resolve(response)
          } else {
              reject(response.data.error)
          }
      })
      .catch((error)=>{
          reject(error)
      })
  })
   }
   addcustomPlan(custome_data) 
   {
     return new Promise((resolve , reject)=>{
      axios.post(`${PlanConfig.addcustomPlan}`, custome_data)
      .then((response)=>{
        if (response) {
          resolve(response)
        } else {
          reject(response.data.error)
        }
      })
      .catch((error)=>{
        reject(error)
      })
     })
   }
   payment(paymentData)
   {
    return new Promise((resolve, reject) => {
      axios
        .post(`${PlanConfig.payment}`, paymentData)
        .then((response) => {
          if (response) {
            resolve(response);
          } else {
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  
  payment_failure(id)
  {
    return new Promise((resolve , reject) =>{
      axios
      .post(`${PlanConfig.payment_failure}` , id)
      .then((response)=>{
        if (response) {
          resolve(response);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
    })
  }

}

const planService = new PlanService()

export default planService