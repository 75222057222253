import React from 'react'
import './UserInfo.css'
import { useSelector } from 'react-redux';
function UserInfo(props) {
  const reviewPage = useSelector((state) => state.otp.reviewPage);
  return (
  <div className="info-container">
     {reviewPage ? '': <div className="onboarding">
              <div className="slide-image">
                <img src={props.imgUrl} alt="" />
              </div>
      </div>}
      <div className="info-form">
        <div className= {props.headingText === 'Custom Subscription Plan' ? 'custom-form':(reviewPage ? 'info-form-inner-small':'info-form-inner')}>
       {props.headingText === 'Otp Verification' ? '' : <h1 className={props.h1 === 'personal'?'personal-h1' : (props.h1 === 'restaurant'? 'rest-h1':'otp-h1')}>{props.headingText}</h1>}
          <form onSubmit={props.onSubmit}>
            {props.children}
          </form>
        </div>
      </div>
    </div>
  )
}
export default UserInfo