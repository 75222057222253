import React, { useEffect } from 'react'
import './Pricing.css'
import Pricing_card_info from './Pricing_card_info'
import { useDispatch } from 'react-redux';
import AOS from 'aos';
import 'aos/dist/aos.css';
function Pricingpage() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
       <div className='pricing_page'>
         <div className='pricing_page_heading'  data-aos="fade-up"  data-aos-duration="500">
           <h1>Subscription Choices</h1>
         </div>
         <div className='pricing_card_and_info'>
            <Pricing_card_info/>
         </div>
       </div>
      
    </>
  )
}

export default Pricingpage
