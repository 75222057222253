import React, { useEffect, useState } from 'react';
import Single_page from '../../components/single_page/Single_page';
import '../../components/single_page/Single_page.css';
import '../../components/css/common.css';
import { Helmet } from 'react-helmet';

function Streamlined_delivery_operations() {
  const peras = [
    `
    Streamlining your delivery operations is essential for ensuring timely and efficient service to your customers. With Printhook's, you can optimize every aspect of your delivery process, from order processing to routing and tracking.
    `,
    `
    Our centralized platform offers comprehensive solutions for managing your delivery operations. By consolidating all order information in one place, Printhook's simplifies the entire process, allowing you to focus on providing exceptional service to your customers.
    `,
    `
    With Printhook's, order processing becomes more efficient than ever before. Our platform automates many aspects of the process, reducing manual errors and speeding up delivery times. From order aggregation to dispatching, Printhook's handles it all seamlessly.
    `,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Helmet>
        <meta
          name='description'
          content="Optimize your delivery operations with Printhook's. Our centralized platform simplifies order processing, routing, and tracking for seamless deliveries."
        />
        <meta
          name='keywords'
          content='streamlined delivery operations, order processing, routing, tracking, centralized platform, efficient deliveries'
        />
        <title>Streamlined Delivery Operations - Printhook's</title>
      </Helmet>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading="Streamlined Delivery Operations - Printhook's"
        img1='/assets/img/useimg.png'
        peras={peras}
        navbar={false}
      >
        <div className='order_managment'>
          <p>
            Routing is a critical aspect of delivery operations, and Printhook's
            excels in this area. Our platform optimizes delivery routes based on
            factors like distance, traffic, and delivery windows, ensuring that
            drivers take the most efficient paths to reach their destinations.
          </p>

          <p>
            Tracking deliveries in real-time is essential for both you and your
            customers. Printhook's provides robust tracking features that allow
            you to monitor the progress of every delivery from start to finish.
            With instant updates and notifications, you can keep your customers
            informed every step of the way.
          </p>
          <p>
            Printhook's centralized platform also offers valuable insights into
            your delivery operations. From performance metrics to customer
            feedback, our platform provides the data you need to continually
            improve your processes and enhance the overall delivery experience.
          </p>
          <p>
            By choosing Printhook's for your delivery operations, you're
            choosing efficiency, reliability, and customer satisfaction. Let us
            help you streamline your delivery operations and take your business
            to new heights
          </p>
        </div>
      </Single_page>
    </>
  );
}

export default Streamlined_delivery_operations;
