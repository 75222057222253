import React, { useEffect } from 'react'
import './About_menu_control.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
function About_menu_control(props) {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
    <div className='about_menu_page'>    

    <div className='about_menu_text_and_image'>
    <div className='about_menu_hedading'>
      <h1 data-aos="fade-up"  data-aos-duration="1000">{props.heading}</h1>
      </div>
        <div className='about_menu_text' data-aos="fade-up" data-aos-duration="500">
            <p dangerouslySetInnerHTML={{ __html: props.pera1 }}></p>
            {props.pera2 && <p dangerouslySetInnerHTML={{ __html: props.pera2 }}></p> }
        </div>
        <div className='about_menu_image' data-aos="fade-up" data-aos-duration="500">
            <img src={props.img}/>
        </div>
    </div>
    </div>
    </>
  )
}

export default About_menu_control
