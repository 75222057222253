import { Checkbox } from '@mui/material'
import React, { useState } from 'react'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useEffect } from 'react';

function PricingCard({ id, title, name , price, is_month, selected, handleCheckboxClick , plan_term , plan_type , plan_id}) {
    
    return (
        <div>
            <div className={`single_pricing_card ${plan_id !== undefined ? 'change_pricing':''} ${selected ? 'card_active' : ''}`} onClick={() => handleCheckboxClick(id , name , plan_term , plan_type)}>
                <div className='checkbox_and_planname'>
                    <Checkbox
                        checked={selected}
                        icon={<RadioButtonUncheckedIcon />}
                        checkedIcon={<CheckCircleOutlineIcon/>}
                        sx={{
                            '& .MuiSvgIcon-root': { fontSize: 35 }, '&.Mui-checked': {
                                color: 'white',
                            },
                        }}
                        onClick={() => handleCheckboxClick(id , name , plan_term , plan_type)}
                    />
                    <div className='plan_name'>
                        <h2>{title}</h2>
                    </div>
                </div>
                <div className='card_price'>
                    {id !== 'custom' ? <>
                        <div className='doler_symbol'>
                            $
                        </div>
                        <div className='actual_price'>
                            {price}
                        </div>
                        <div className='month_year'>
                            {is_month ? '/Month' : '/Year'}
                        </div>
                    </> :
                        <div className='custom_price'>
                            {price}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default PricingCard
