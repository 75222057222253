import React from 'react'
import AfterPayment from '../../components/after_payment/AfterPayment'
import { paymentFailure } from '../../redux/planSlice';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

function PaymentFail() {
  const dispatch = useDispatch();
  const location = useLocation();

  const tryAgainClick = () => {
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');

    dispatch(paymentFailure({ id:+id}))
      .unwrap()
      .then((response) => {

        const successUrl = response.data.plan_stripe_url;
        let a = document.createElement('a');
        a.href = successUrl;
        a.click();

      })
      .catch((error) => {
        console.log(error);
      })

  }

  const textContent = (
    <>
      Sorry for the inconvenience, your transaction has been failed.{' '}
      <span onClick={tryAgainClick} className='try_again_link'>Try again</span> to complete your payment.
    </>
  );
  return (
    <div>
      <AfterPayment text={textContent} image="/assets/img/fail_payment.svg" page="fail" />
    </div>
  )
}

export default PaymentFail
