import React, { useState } from 'react';
import './Pos_integration_page.css';
import Single_page from '../../components/single_page/Single_page';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import data from '../../pages/pos_integration_page/POS_int_data';
function Pos_integration_page() {
  const peras = [
    ` Integrate Digital Ordering Platforms Directly Into Your <span>POS System</span>, Receive Orders From Multiple Delivery Platforms—Without the Headache. Printhook's partners with more than 150 third-party platforms and can send orders seamlessly through over 100 POS systems into your kitchen. Our majority client base is connected through <span>CLOVER POS</span> integration.`,
    'Streamline and Simplify Operations No need for dozens of ordering tablets or error-prone manual order entry. Customers can place orders on any third-party app, and the order will be sent to your <span>POS</span>automatically through our digital ordering integration solution. It’s that simple.',
    `  Printhook's digital ordering <span>POS integration</span> solution works with third-party apps both big and small, meaning new customers can discover you no matter where they are ordering from.`,
  ];
  const [openItem, setOpenItem] = useState(null);

  const toggleItem = (index) => {
    if (openItem === index) {
      setOpenItem(null);
    } else {
      setOpenItem(index);
    }
  };
  return (
    <>
      <Single_page
        image='/assets/img/pos_integration_image.svg'
        heading='POS Integrations'
        img1='/assets/img/pos1_img.svg'
        peras={peras}
        navbar={true}
      >
        <div className='pos_int_faq'>
          <h1>FAQs POS Integrations</h1>
          <div className='faq_question_and_ans'>
            {data.map((item, index) => (
              <div className='question_and_ans' key={index}>
                <div
                  className='question_and_plusicon'
                  onClick={() => toggleItem(index)}
                >
                  <h3>{item.question}</h3>
                  <div className='plus_icon'>
                    {openItem === index ? (
                      <IconButton onClick={() => toggleItem(index)}>
                        <RemoveIcon sx={{ color: 'rgba(105, 111, 221, 1)' }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => toggleItem(index)}>
                        <AddIcon sx={{ color: 'rgba(105, 111, 221, 1)' }} />
                      </IconButton>
                    )}
                  </div>
                </div>
                <Collapse in={openItem === index} timeout='auto' unmountOnExit>
                  <div className='ans'>{item.ans}</div>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </Single_page>
    </>
  );
}

export default Pos_integration_page;
