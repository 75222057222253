import React, { useEffect, useState, useRef } from 'react';
import './Hover_scroll.css';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Pagination, Autoplay } from 'swiper/modules';
// ... (imports remain the same)
import AOS from 'aos';
import 'aos/dist/aos.css';
function Hover_scroll() {
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiper, setSwiper] = useState(null);
  const swiperRef = useRef(null);

  useEffect(() => {
    if (swiperRef.current && !swiper) {
      setSwiper(swiperRef.current.swiper);
    }
  }, [swiper]);

  useEffect(() => {
    AOS.init();
  }, [])

  const handleMouseEnter = (index) => {
    console.log(swiper);
    if (swiper) {
      swiper.autoplay.stop();
      setActiveSlide(index);
      swiper.slideToLoop(index);
    }
  };

  const handleMouseLeave = () => {
    if (swiper) {
      swiper.autoplay.start();
    }
  };

  const peragraphs = [
    { logo: '/assets/img/orderlogo.svg', text: 'Mange online <span>ORDERS</span> from one dashboard' },
    { logo: '/assets/img/menulogo.svg', text: 'Control all your online <span>MENUS</span> at one' },
    { logo: '/assets/img/stocklogo.svg', text: 'Update <span>STOCK</span> availability in real time' },
    { logo: '/assets/img/supportlogo.svg', text: 'Best-in-class customer <span>SUPPORT</span>' },
    { logo: '/assets/img/reportlogo.svg', text: 'Make better decisions with detailed <span>REPORTING</span>' },
  ]

  const sliding_images = [
    { image: '/assets/img/order.svg' },
    { image: '/assets/img/menu.svg' },
    { image: '/assets/img/stock.svg' },
    { image: '/assets/img/support.svg' },
    { image: '/assets/img/report.svg' },

  ]
  return (
    <>
      <div className="image-slider-container">
        <div className='text_and_image'>
          <div className='left_text' data-aos="fade-right"  data-aos-duration="500">
            {peragraphs.map((peragraph, index) => (
              <div className='peragraph_logo'>
                <img src={peragraph.logo} loading='lazy'/>
                <p dangerouslySetInnerHTML={{ __html: peragraph.text }} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={handleMouseLeave} />
              </div>
            ))}
          </div> 
          <div className='right_imag' data-aos="fade-left"  data-aos-duration="500">
            <Swiper
              spaceBetween={30}
              effect={'fade'}
              autoplay={{ delay: 1000, disableOnInteraction: false }}
              loop={true}
              modules={[Autoplay, EffectFade, Navigation, Pagination]}
              className="mySwiper"
              ref={swiperRef}
              onSlideChange={(swiper) => setActiveSlide(swiper.realIndex)}
            >
              {sliding_images.map((sliding_img, index) => (
                <SwiperSlide>
                  <img src={sliding_img.image} loading="lazy"/>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
      <div id='blog_stop_scroll'></div>

    </>
  );
}

export default Hover_scroll;
