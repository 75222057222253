import React, { useRef, useState } from 'react';
import { Box, Grid, InputLabel, TextField, Button } from '@mui/material';
import { createContact } from '../../redux/restaurantSlice';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ReCAPTCHA from 'react-google-recaptcha';
import { mobileSpamPatterns } from '../../components/common/common';

const ContactForm = () => {
  const recaptchaRef = useRef();
  const dispatch = useDispatch();
  const [contactData, setContactData] = useState({
    name: '',
    email: '',
    phone_number: '',
    restaurant_name: '',
    restaurant_address: '',
    pos: '',
    number_of_outlets: '',
    notes: '',
    source: 'Contact',
    number_of_integrations: 0,
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    phone_number: '',
    restaurant_name: '',
    restaurant_address: '',
    pos: '',
    number_of_outlets: '',
    notes: '',
  });

  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const handleOnChange = (event) => {
    const fieldName = event.target.name;
    let fieldValue = event.target.value;

    // Rest Name Valiation...
    if (fieldName === 'restaurant_name' && fieldValue.length < 5) {
      setErrors({
        ...errors,
        restaurant_name: 'Restaurant name must be at least 5 characters long.',
      });
    } else if (fieldName === 'restaurant_address') {
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          restaurant_address: 'Restaurant address is required',
        });
      } else {
        setErrors({
          ...errors,
          restaurant_address: '',
        });
      }
    } else if (fieldName === 'name') {
      const nameRege = /^[A-Za-z\s]+$/;
      if (fieldValue.length < 1) {
        setErrors({
          ...errors,
          name: 'Name field is required',
        });
      } else if (!nameRege.test(fieldValue)) {
        setErrors({
          ...errors,
          name: 'Name should not contain numbers.',
        });
      } else {
        setErrors({
          ...errors,
          name: '',
        });
      }
    } else if (fieldName === 'email') {
      const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          email: 'Email is required',
        });
      } else if (!emailRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          email: 'Email is not valid',
        });
      } else {
        setErrors({
          ...errors,
          email: '',
        });
      }
    } else if (fieldName === 'phone_number') {
      fieldValue = fieldValue.replace(/\D/g, '');
      const phoneRegex = /^\d{10}$/;
      if (fieldValue.length > 10) {
        fieldValue = fieldValue.slice(0, 10);
      } else if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          phone_number: 'Phone number is required',
        });
      } else if (fieldValue.length < 10) {
        setErrors({
          ...errors,
          phone_number: 'Phone Number must have maximum 10 digits',
        });
      } else if (!phoneRegex.test(fieldValue)) {
        setErrors({
          ...errors,
          phone_number: 'Phone number is not valid',
        });
      }
      else if (mobileSpamPatterns.includes(fieldValue.toLowerCase())) {
        setErrors({
          ...errors,
          phone_number: 'Please enter a valid phone number',
        });
      } 
      else {
        setErrors({
          ...errors,
          phone_number: '',
        });
      }
    } else if (fieldName === 'number_of_outlets') {
      const numberRegex = /^[1-9][0-9]*$/;
      if (fieldValue.length === 0) {
        setErrors({
          ...errors,
          number_of_outlets: 'Number of location is required',
        });
      }
      else if (!numberRegex.test(fieldValue)) {
        fieldValue = '';
      } 
       else {
        setErrors({
          ...errors,
          number_of_outlets: '',
        });
      }
    } else {
      setErrors({
        ...errors,
        [fieldName]: '',
      });
    }

    setContactData((prevData) => ({
      ...prevData,
      [fieldName]: fieldValue,
    }));
  };

  const contactFormSubmit = () => {
    if (!recaptchaToken) {
      toast.error('Please complete the reCAPTCHA', {
        position: 'top-right',
        autoClose: 1000,
        closeButton: false,
      });
      return;
    }

    const finalContactData = {
      ...contactData,
      recaptchaToken,
    };
    recaptchaRef.current.reset();
    setRecaptchaToken(null);
    dispatch(createContact(finalContactData))
    .then((response) => {
      if (response.payload.status === 201) {
        Swal.fire({
          icon: 'success',
          title: 'Thank you!',
          html: '<p>Our team will connect with you shortly.</p>',
          showConfirmButton: false,
          timer: 2000,
        });
        setContactData({
          name: '',
          email: '',
          phone_number: '',
          restaurant_name: '',
          restaurant_address: '',
          pos: '',
          number_of_outlets: '',
          source: 'Contact',
            notes: '',
          });
        } else if (response.payload.status === 400) {
          toast.error('The email already exists', {
            position: 'top-right',
            autoClose: 1000,
            closeButton: false,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const disabled =
    errors.restaurant_name ||
    errors.restaurant_address ||
    errors.phone_number ||
    errors.email ||
    contactData.email.trim() === '' ||
    contactData.restaurant_name.trim() === '' ||
    contactData.restaurant_address.trim() === '' ||
    contactData.phone_number.trim() === '' ||
    contactData.name === '' ||
    contactData.number_of_outlets.trim() === '' ||
    !recaptchaToken;

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        closeButton={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
      <form noValidate autoComplete='off'>
        <Box className='contact_form'>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                  Name*
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='name'
                  name='name'
                  value={contactData.name}
                  fullWidth
                  size='small'
                  className='input-field'
                  variant='filled'
                  onChange={handleOnChange}
                  placeholder='Your name'
                  helperText={errors.name}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                  Restaurant Name*
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='restaurant_name'
                  name='restaurant_name'
                  value={contactData.restaurant_name}
                  fullWidth
                  size='small'
                  className='input-field'
                  variant='filled'
                  onChange={handleOnChange}
                  helperText={errors.restaurant_name}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                  Restaurant Email*
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='email'
                  name='email'
                  value={contactData.email}
                  fullWidth
                  size='small'
                  className='input-field'
                  variant='filled'
                  onChange={handleOnChange}
                  helperText={errors.email}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                 Phone Number*
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='phone_number'
                  name='phone_number'
                  value={contactData.phone_number}
                  fullWidth
                  size='small'
                  className='input-field'
                  variant='filled'
                  onChange={handleOnChange}
                  helperText={errors.phone_number}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                  Restaurant Address*
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='restaurant_address'
                  name='restaurant_address'
                  value={contactData.restaurant_address}
                  fullWidth
                  size='small'
                  className='input-field'
                  variant='filled'
                  onChange={handleOnChange}
                  helperText={errors.restaurant_address}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                  POS
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='pos'
                  name='pos'
                  value={contactData.pos}
                  fullWidth
                  size='small'
                  className='input-field'
                  variant='filled'
                  onChange={handleOnChange}
                  placeholder='What POS are you running?'
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                  Number of Locations*
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='number_of_outlets'
                  name='number_of_outlets'
                  value={contactData.number_of_outlets}
                  fullWidth
                  size='small'
                  className='input-field'
                  variant='filled'
                  onChange={handleOnChange}
                  helperText={errors.number_of_outlets}
                />
              </Grid>
            </Grid>
          </Box>
          <Box marginBottom={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <InputLabel shrink sx={{ fontSize: 22 }}>
                  Notes
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  id='notes'
                  name='notes'
                  value={contactData.notes}
                  fullWidth
                  size='small'
                  placeholder='Please leave us a note'
                  className='input-field'
                  variant='filled'
                  multiline
                  rows={4}
                  onChange={handleOnChange}
                />
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              marginBottom: 5,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <ReCAPTCHA
            ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE}
              onChange={(token) => setRecaptchaToken(token)}
            />
            <Button
              className={`${
                disabled ? 'submit_button_disable' : 'submit_button'
              }`}
              sx={{
                marginTop: '10px',
                fontSize: 15,
                fontWeight: 600,
                textTransform: 'none',
              }}
              onClick={contactFormSubmit}
              disabled={disabled}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
};

export default ContactForm;
